import React from 'react';

const Labels = () => {
  return (
    <div className='card shadow'>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>ID</th>
                <th className='min-w-120px'>Void</th>
                <th className='min-w-120px'>Print</th>
                <th className='min-w-120px'>Printed</th>
                <th className='min-w-120px'>Received</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-140px'>Tracking</th>
                <th className='min-w-150px'>Contents</th>
                <th className='min-w-120px'>State</th>
                <th className='min-w-120px'>Value</th>
                <th className='min-w-120px'>Weight</th>
                <th className='min-w-120px'>Width</th>
                <th className='min-w-120px'>Length</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Labels;
