import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { AddReturns } from './AddReturns';
import axios from 'axios';
import { ReturnDetails } from './ReturnDetails';
import Loader from "react-js-loader";
import { UploadReturnImg } from './UploadReturnImg';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const Returns = () => {

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [showCreateAppModal2, setShowCreateAppModal2] = useState(false);
  const [showCreateAppModal3, setShowCreateAppModal3] = useState(false);

  const [returnsData, setReturnsData] = useState([]);
  // const [returnInput, setReturnInput] = useState("");
  // const [errorMsg, setErrorMsg] = useState("");
  const [detailsData, setDetailsData] = useState({});
  const [loading, setLoading] = useState(false);
  // const [editMode, setEditMode] = useState(null);
  const [refund_code, setRefund_code] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_RETURNS_DATA = `${API_URL}/get-refund-requests`;
  // const ADD_RETURNS = `${API_URL}/refund-request-create`;
  const apiToken = getLocalData().api_token;

  const getReturnsData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_RETURNS_DATA, config)
      .then((response) => {
        setReturnsData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // const addReturns = async (id) => {
  //   setErrorMsg("");
  //   const data = {
  //     reverse_order: returnInput,
  //     refund_code: id
  //   }
  //   if (!returnInput) {
  //     setErrorMsg("Enter Tracking number");
  //   } else {
  //     await axios.post(ADD_RETURNS, data)
  //       .then((response) => {
  //         console.log(response.data);
  //         getReturnsData();
  //         setEditMode(null)
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   };
  // };

  useEffect(() => {
    getReturnsData();
  }, []);

  return (
    <div>
      <AddReturns
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
        getReturnsData={getReturnsData}
      />
      <ReturnDetails
        show={showCreateAppModal2}
        handleClose={() => {
          setShowCreateAppModal2(false);
        }}
        detailsData={detailsData}
      />
      <UploadReturnImg
        show={showCreateAppModal3}
        handleClose={() => {
          setShowCreateAppModal3(false);
        }}
        getReturnsData={getReturnsData}
        refund_code={refund_code}
      />
      <h1>Returns</h1>
      <div className='card shadow p-5'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>ID</th>
                  <th className='min-w-120px'>Name</th>
                  <th className='min-w-120px'>Tracking No.</th>
                  <th className='min-w-120px'>Commitment No.</th>
                  <th className='min-w-120px'>Return</th>
                  <th className='min-w-120px'>Status</th>
                  <th className='min-w-120px'>Date</th>
                </tr>
              </thead>
              {returnsData.length === 0 &&
                <div className='fs-5 fw-semibold text-gray-600'>
                  <p>No Returns Available.</p>
                </div>
              }
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <tbody>
                  {returnsData.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {index + 1}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {val.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {val.tracking_id}&nbsp;&nbsp;
                            <i
                              onClick={() => {
                                setDetailsData(val);
                                setShowCreateAppModal2(true);
                              }} className="fa-solid fa-circle-info"></i>
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {val.commit_no}
                          </span>
                        </td>
                        <td>
                          {/*{val.reverse_order ?
                            <div>
                              {editMode == val.refund_code ? (
                                <div>
                                  <input
                                    type='text'
                                    value={returnInput}
                                    onChange={(e) => setReturnInput(e.target.value)}
                                    className='form-control w-50'
                                    placeholder='Enter Return Tracking number'
                                  />
                                  {errorMsg && <p className='text-danger position-absolute fw-semibold'>{errorMsg}</p>}

                                  <div className="d-flex justify-content-start mt-5">
                                    <button
                                      onClick={() => addReturns(val.refund_code)}
                                      className='btn btn-sm btn-success'>
                                      Update
                                    </button>&nbsp;&nbsp;
                                    <button
                                      onClick={() => {
                                        setEditMode(null);
                                        setErrorMsg("");
                                        setReturnInput("");
                                      }}
                                      className='btn btn-sm btn-danger'>
                                      Cancle
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex'>
                                  <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                    {val.reverse_order}
                                  </span>
                                  &nbsp;&nbsp;
                                  {val.status == 1 && (<i
                                    onClick={() => {
                                      setEditMode(val.refund_code);
                                      setReturnInput(val.reverse_order);
                                    }}
                                    className="fa-solid text-warning fa-pencil"
                                    style={{ cursor: "pointer" }}>
                                  </i>)}
                                </div>
                              )}
                            </div>
                            : (
                              <div>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    value={returnInput}
                                    onChange={(e) => setReturnInput(e.target.value)}
                                    className='form-control w-50'
                                    placeholder='Enter Return Tracking number'
                                  />
                                  <button onClick={() => addReturns(val.refund_code)} className='btn btn-success'><i className="fa-solid fa-plus"></i></button>
                                </div>
                                {errorMsg && <p className='text-danger position-absolute fw-semibold'>{errorMsg}</p>}
                              </div>
                            )}*/}
                          {val.status == 1 ? (
                            <button
                              className='btn btn-warning btn-sm'
                              onClick={() => {
                                setShowCreateAppModal3(true);
                                setRefund_code(val.refund_code);
                              }}
                            >
                              Add Returns
                            </button>
                          ) : (
                            <button
                              className='btn btn-success btn-sm'>
                              Added
                            </button>
                          )}
                        </td>
                        <td>
                          {val.status == 0 && (
                            <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
                          )}
                          {val.status == 1 && (
                            <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                          )}
                          {val.status == 2 && (
                            <span className='badge badge-light-info fs-8 fw-bold'>Active</span>
                          )}
                          {val.status == 3 && (
                            <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Expired</span>
                          )}
                        </td>
                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                          {val.date}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/*<div className='footer_btn3'>
          <button onClick={() => setShowCreateAppModal(true)}><i className="fa-solid fa-plus"></i>&nbsp; Add Returns</button>
        </div>*/}
      </div>
    </div>
  );
}

export default Returns;
