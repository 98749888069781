import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import Loader from "react-js-loader";
import CurrencyFormat from 'react-currency-format';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const MyOrders = () => {

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);

  const apiToken = getLocalData().api_token;

  const API_URL = process.env.REACT_APP_API_URL;
  const ORDERS = `${API_URL}/user/orders`;

  const getOrderData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(ORDERS, config)
      .then((response) => {
        setOrderData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrderData();
  }, []);

  return (
    <div>
      <h1>My Orders</h1>
      <div className='card shadow'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Order Id</th>
                  <th>Void</th>
                  <th>Status</th>
                  <th>Method</th>
                  <th>QTY</th>
                  <th>Commission</th>
                  <th>Total</th>
                  <th>Created Date</th>
                  <th>Count</th>
                  <th>Tracking Number</th>
                  <th>Modified Date</th>
                </tr>
              </thead>
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <tbody>
                  {
                    orderData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.order_no}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary mb-1 fs-6'></span>
                          </td>
                          <td>
                            {val.status == 0 && (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
                            )}
                            {val.status == 1 && (
                              <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                            )}
                            {val.status == 2 && (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Active</span>
                            )}
                            {val.status == 3 && (
                              <span className='badge badge-light-danger fs-8 danger_active'>Expired</span>
                            )}
                            {val.status == 4 && (
                              <span className='badge badge-light-warning fs-8 fw-bold warning_active'>In prpgress</span>
                            )}
                            {val.status == 5 && (
                              <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Return Pending</span>
                            )}
                            {val.status == 6 && (
                              <span className='badge badge-info fs-8 p-3 fw-bold'>Return Active</span>
                            )}
                            {val.status == 7 && (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Return Completed</span>
                            )}
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary mb-1 fs-6'></span>
                          </td>
                          <td className='text-dark text-hover-primary fs-6'>{val.commited_qty}</td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.commissiom).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark  text-hover-primary fs-6'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.total).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.date}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.count}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.tracking_id}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.departure_date}</span>
                          </td>
                          {/*<td className='text-end'>
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTIcon iconName='switch' className='fs-3' />
                            </span>
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </span>
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                              <KTIcon iconName='trash' className='fs-3' />
                            </span>
                            </td>*/}
                        </tr>
                      );
                    })
                  }
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrders;
