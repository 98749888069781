import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Deals from '../pages/deals/Deals'
import MyCommitments from '../pages/myCommitments/MyCommitments'
import MyOrders from '../pages/orders/MyOrders'
import OrderDetails from '../pages/orders/OrderDetails'
import OrderPriceDispute from '../pages/orders/OrderPriceDispute'
import TrackingManagement from '../pages/trackingManagment/TrackingManagement'
import ShippingLabels from '../pages/shippingLabels/ShippingLabels'
import RequestPayments from '../pages/payments/RequestPayments'
import ACHPayments from '../pages/payments/ACHPayments'
import Echecks from '../pages/payments/Echecks'
import PaymentDetails from '../pages/payments/PaymentDetails'
import Returns from '../pages/returns/Returns'
import Support from '../pages/support/Support'
import Referrals from '../pages/referrals/Referrals'
import FAQs from '../pages/FAQs/FAQs'
import Help from '../pages/help/Help'
import ReportBug from '../pages/help/ReportBug'
import TermsCondition from '../pages/terms&conditions/TermsCondition'
import Profile from '../pages/profile/Profile'

const PrivateRoutes = () => {
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route path='deals' element={<Deals />} />

        <Route path='mycommitments' element={<MyCommitments />} />

        <Route path='orders/myorders' element={<MyOrders />} />
        <Route path='orders/ordersdetails' element={<OrderDetails />} />
        <Route path='orders/ordersprice' element={<OrderPriceDispute />} />

        <Route path='tracking' element={<TrackingManagement />} />

        <Route path='shippinglabels' element={<ShippingLabels />} />

        <Route path='payments/requestpayments' element={<RequestPayments />} />
        <Route path='payments/achpayments' element={<ACHPayments />} />
        <Route path='payments/echeckspayments' element={<Echecks />} />
        <Route path='payments/paymentdetails' element={<PaymentDetails />} />

        <Route path='returns' element={<Returns />} />

        <Route path='support' element={<Support />} />

        <Route path='referrals' element={<Referrals />} />

        <Route path='faqs' element={<FAQs />} />

        <Route path='help/help' element={<Help />} />
        <Route path='help/reportbug' element={<ReportBug />} />

        <Route path='termsandconditions' element={<TermsCondition />} />

        <Route path='profile' element={<Profile />} />


        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
