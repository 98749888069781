/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
// import { toAbsoluteUrl } from '../../../_metronic/helpers';
// import { PageTitle } from '../../../_metronic/layout/core';
// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
//   CardsWidget7,
//   CardsWidget17,
//   CardsWidget20,
//   ListsWidget26,
//   EngageWidget10,
// } from '../../../_metronic/partials/widgets';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return data !== null ? JSON.parse(data) : null;
};

const DashboardPage = () => {

  const [apiData, setApiData] = useState([]);
  const [commitData, setCommitData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [trackingData, setTrackingData] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [dealLoading, setDealLoading] = useState(false);
  const [commitLoading, setCommitLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [trackingLoading, setTrackingLoading] = useState(false);
  const localData = getLocalData();

  const API_URL = process.env.REACT_APP_API_URL;
  const DEALS_URL = `${API_URL}/dashboard/deals`;
  const GET_COMMIT_URL = `${API_URL}/dashboard/commitments`;
  const ORDERS = `${API_URL}/user/dashboard/orders`;
  const GET_DASHBOARD_DATA = `${API_URL}/user/dashboard`;
  const GET_DASHBOARD_TRACKINGS = `${API_URL}/dashboard/tracking/details`;

  const getCommitments = async () => {
    setCommitLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${localData.api_token}` }
    };
    await axios.get(GET_COMMIT_URL, config)
      .then((response) => {
        setCommitData(response.data.data.reverse());
        setCommitLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setCommitLoading(false);
      });
  };

  const getOrderData = async () => {
    setOrderLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${localData.api_token}` }
    };
    await axios.get(ORDERS, config)
      .then((response) => {
        setOrderData(response.data.data);
        setOrderLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setOrderLoading(false);
      });
  };

  const getTrackingData = async () => {
    setTrackingLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${localData.api_token}` }
    };
    await axios.get(GET_DASHBOARD_TRACKINGS, config)
      .then((response) => {
        setTrackingData(response.data.data);
        setTrackingLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setTrackingLoading(false);
      });
  };

  const getDashboardData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${localData.api_token}` }
    };
    await axios.get(GET_DASHBOARD_DATA, config)
      .then((response) => {
        setDashboardData(response.data.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getApiData = async () => {
    setDealLoading(true);
    try {
      await axios.get(DEALS_URL)
        .then((res) => {
          setApiData(res.data.data);
          setDealLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setDealLoading(false);
        });
    } catch (error) {
      console.error(error);
      setDealLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
    getTrackingData();
    getCommitments();
    getOrderData();
    getApiData();
  }, []);

  return (
    <>
      <div>
        <div className="row">

          <div className="col-3">

            <div className="">
              <div className="card shadow card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100" style={{ backgroundColor: "#fff" }}>
                {/*<div className="d-flex">
                  <div className="p-5">
                    <div
                      className="d-flex flex-center rounded-circle h-60px w-60px"
                      style={{
                        border: "1px dashed rgba(255, 255, 255, 0.4)",
                        backgroundColor: "#fff"
                      }}
                    >
                      <i className="ki-duotone ki-call fs-2qx lh-0">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                        <span className="path8" />
                      </i>
                    </div>
                    </div>*/}
                <div className="card-body pb-0 d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <span className="fs-3 fw-bold me-6"><i className="fa-solid fa-sort fs-2"></i>  Orders</span>
                  </div>
                </div>
                {/*</div>*/}
                <div
                  className="card-footer p-5 pb-2 pt-2"
                  style={{
                    borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                    background: "#fff"
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="fw-bold py-2">
                      <span className="fs-5 text-info d-block">Net Earnings</span>
                      <span className="fs-3 opacity-50">
                        <CurrencyFormat
                          decimalScale={2}
                          value={parseInt(dashboardData?.orderTotalAmount)?.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </span>
                    </div>
                    <div className="fw-bold py-2">
                      <span className="fs-5 d-block">Commission</span>
                      <span className="fs-3 opacity-50">
                        <CurrencyFormat
                          decimalScale={2}
                          value={dashboardData?.orderTotalCommision?.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="card shadow card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100" style={{ backgroundColor: "#fff" }}>
                {/*<div className="d-flex">
                  <div className="p-5">
                    <div
                      className="d-flex flex-center rounded-circle h-60px w-60px"
                      style={{
                        border: "1px dashed rgba(255, 255, 255, 0.4)",
                        backgroundColor: "#fff"
                      }}
                    >
                      <i className="ki-duotone ki-call fs-2qx lh-0">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                        <span className="path8" />
                      </i>
                    </div>
                    </div>*/}
                <div className="card-body pb-0 d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <span className="fs-3 text-success fw-bold me-6"><i className="fa-solid fa-dollar-sign fs-2"></i> Balance</span>
                  </div>
                </div>
                {/*</div>*/}
                <div
                  className="card-footer p-5"
                  style={{
                    borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                    background: "#fff"
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <div className="fw-bold py-2">
                      <span className="fs-3 d-block">
                        {dashboardData.currentBalance !== "" ?
                          <CurrencyFormat
                            decimalScale={2}
                            value={parseFloat(dashboardData.currentBalance).toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true} prefix={'$'}
                          /> :
                          "$0.00"
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="card shadow card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100" style={{ backgroundColor: "#FFF" }}>
                {/*<div className="d-flex">
                  <div className="p-5">
                    <div
                      className="d-flex flex-center rounded-circle h-40px w-40px"
                      style={{
                        border: "1px dashed rgba(255, 255, 255, 0.4)",
                        backgroundColor: "#fff"
                      }}
                    >
                      <i className="ki-duotone ki-call fs-2qx lh-0">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                        <span className="path8" />
                      </i>
                    </div>
                    </div>*/}
                <div className="card-body pb-0">
                  <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-bold"><i className="fa-solid fs-2 fa-chart-column"></i> Active Trackings</span>
                  </div>
                </div>
                {/*</div>*/}
                <div
                  className="card-footer p-5"
                  style={{
                    borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                    background: "#fff"
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <div className="fw-bold py-2">
                      <span className="fs-3 d-block">{dashboardData?.tracking}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className="col-3">
            <div className="card shadow">
              <div className="d-flex justify-content-center align-item-senter">
                <div className="mt-5">
                  <span className='fs-3  fw-semibold'>Achievement</span>
                </div>
              </div>
              <div className="card-body px-4 py-7 pt-2">
                <div className="row">
                  <div className="col-7">
                    <div>
                      <span className='fs-6 text-danger fw-semibold'>Total Withdrawed:</span>
                    </div>
                    <div>
                      <span className='fs-6 fw-semibold'>Processing:</span>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>
                        <CurrencyFormat
                          decimalScale={2}
                          value={parseInt(dashboardData?.withdrawPaid)?.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </span>
                    </div>
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>
                        <CurrencyFormat
                          decimalScale={2}
                          value={parseInt(dashboardData?.withdrawPending)?.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <Link to="/profile?activeTab=kt_tab_pane_6" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                    <span>Withdraw Money</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className='card shadow  mt-5'>
              <div className="d-flex justify-content-center align-item-senter">
                <div className="mt-5 pb-0">
                  <span className='fs-3 fw-semibold'>Commitments</span>
                </div>
              </div>
              <div className="card-body px-4 py-7 pt-2">
                <div className="row">
                  <div className="col-7">
                    <div>
                      <span className='fs-6 fw-semibold'>Total Commit:</span>
                    </div>
                    <div>
                      <span className='fs-6 fw-semibold'>Archived:</span>
                    </div>
                    <div>
                      <span className='fs-6 fw-semibold'>Pending:</span>
                    </div>
                    <div>
                      <span className='fs-6 fw-semibold'>Processing:</span>
                    </div>
                    <div>
                      <span className='fs-6 fw-semibold'>Return:</span>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>{dashboardData?.totalCommit}</span>
                    </div>
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>{dashboardData?.archivedCommit}</span>
                    </div>
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>{dashboardData?.pendingCommit}</span>
                    </div>
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>{dashboardData?.inProcessCommit}</span>
                    </div>
                    <div className='text-muted'>
                      <span className='fs-6 fw-semibold'>{dashboardData?.returnCommit}</span>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <Link to="/tracking" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                    <span>Add tracking number</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className='card shadow'>
              <div className="d-flex justify-content-between pt-3 px-5 align-item-senter">
                <div className="mt-5 pb-0">
                  <span className='fs-3 fw-semibold'>Latest Deal</span>
                </div>
                <div className="text-center mt-5">
                  <Link to="/deals" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                    <span>View more...</span>
                  </Link>
                </div>
              </div>
              {dealLoading ? (
                <div className="item mt-10 mb-10">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <div className="card-body px-2">
                  {apiData.map((val, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className='col-2 text-center'>
                          <img src={val?.default_color?.image} className="img-sm" />
                        </div>
                        <div className="col-10">
                          <Link to="/deals">
                            <div className='fs-6 text-dark fw-semibold deal_name_dashboard'>
                              <span>{val.name}</span>
                            </div>
                            <div className='fs-7 text-dark fw-semibold'>
                              <span>Price:
                                &nbsp;<CurrencyFormat
                                  decimalScale={2}
                                  value={parseInt(val.default_color?.price).toFixed(2)}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              </span>
                              <span className='text-warning fs-9 fw-bold mx-5'>Expires: {val.expires}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
      {/*
      <div className='mt-5'>
        <div>
          <h1 className="page-heading d-flex text-gray-700 fw-bold fs-3 flex-column justify-content-center pt-10 pb-5 my-0">Payments</h1>
        </div>
        <div className="row gy-5 g-xl-10">

          <div className="col-xl-1 mb-5 mb-xl-10"></div>

          <div className="col-sm-6 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <i className="ki-duotone ki-compass fs-2hx text-gray-600">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
                <div className="d-flex flex-column my-5">
                  <div className="m-0 mt-2">
                    <span className="fw-semibold fs-2 text-gray-800">$0.00 </span>
                  </div>
                  <span className="fw-semibold fs-3 text-gray-400 lh-1 ls-n2">
                    Adjustment
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <i className="ki-duotone ki-chart-simple fs-2hx text-gray-600">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                  </i>
                </div>
                <div className="5">
                  <div className="m-0">
                    <span className="fw-semibold fs-2 text-gray-800">
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseInt(dashboardData?.withdrawRequest)?.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </span>
                  </div>
                  <span className="fw-semibold fs-3 text-gray-400 lh-1 ls-n2">
                    Requested
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <i className="ki-duotone ki-abstract-39 fs-2hx text-gray-600">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
                <div className="5">
                  <div className="m-0">
                    <span className="fw-semibold fs-2 text-gray-800">
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseInt(dashboardData?.withdrawPending)?.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </span>
                  </div>
                  <span className="fw-semibold fs-3 text-gray-400 lh-1 ls-n2">
                    Processing
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <i className="ki-duotone ki-map fs-2hx text-gray-600">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </div>
                <div className="5">
                  <div className="m-0">
                    <span className="fw-semibold fs-2 text-gray-800">
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseInt(dashboardData?.withdrawPaid)?.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </span>
                  </div>
                  <span className="fw-semibold fs-3 text-gray-400 lh-1 ls-n2">
                    Paid
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-2 mb-5 mb-xl-10">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <i className="ki-duotone ki-abstract-35 fs-2hx text-gray-600">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
                <div className="5">
                  <div className="m-0">
                    <span className="fw-semibold fs-2 text-gray-800">$0.00 </span>
                  </div>
                  <span className="fw-semibold fs-3 text-gray-400 lh-1 ls-n2">
                    Sent
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-1 mb-5 mb-xl-10"></div>

        </div>

      </div>

      <div>
        <div className="card shadow">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-3">
                <div className='fs-3 fw-semibold d-flex flex-column align-items-center' style={{ marginTop: "10%" }}>
                  <span>What would you like to do?</span>
                </div>
              </div>
              <div className="col-9">
                <div>
                  <div className="row">

                    <div className="col-6">
                      <Link to="/tracking" className='btn'>
                        <div className="card" style={{ height: "60px", paddingLeft: "50px", paddingRight: "50px", backgroundColor: "#4E7DBC" }}>
                          <div className='fs-4 fw-semibold text-light text-center align-items-center m-auto'>
                            <span>Add tracking number</span>
                          </div>
                        </div>
                      </Link>
                    </div>

                    {/*<div className="col-3">
                    <div className="card" style={{ height: "60px" }}>
                      <div className='fs-4 fw-semibold text-muted text-center align-items-center m-auto'>
                        <span>Request Shipping Label</span>
                      </div>
                    </div>
                  </div>

                    <div className="col-6">
                      <Link to="/profile?activeTab=kt_tab_pane_6" className='btn'>
                        <div className="card" style={{ height: "60px", paddingLeft: "50px", paddingRight: "50px", backgroundColor: "#EA2467" }}>
                          <div className='fs-4 fw-semibold text-light text-center align-items-center m-auto'>
                            <span>Withdraw Money</span>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-3">
                    <div className="card" style={{ height: "60px" }}>
                      <div className='fs-4 fw-semibold text-muted text-center align-items-center m-auto'>
                        <span>Payment Details</span>
                      </div>
                    </div>
                </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
              */}

      <div className='mt-10'>
        <div className="row">

          <div className="col-sm-12 col-md-4">
            <div className="card shadow border card-flush h-lg-100">
              <div className="card-header d-flex justify-content-between  px-5 align-item-senter">
                <div className="mt-5 pb-0">
                  <span className='fs-3 fw-semibold'>Latest Commitments</span>
                </div>
                {commitData.length !== 0 &&
                  <div className="text-center mt-5">
                    <Link to="/mycommitments" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                      <span>View more...</span>
                    </Link>
                  </div>
                }
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive table_container">
                  <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                    {commitLoading ? (
                      <tr className="item">
                        <td><Loader type="spinner-cub" bgColor={"#FD8908"} size={50} /></td>
                      </tr>
                    ) : (
                      <tbody>
                        {commitData.length === 0 &&
                          <div className='fs-5 fw-semibold text-gray-600'>
                            <div className="text-center mt-5">
                              <Link to="/deals" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                                <span>Commit Now</span>
                              </Link>
                            </div>
                          </div>
                        }
                        {commitData.length !== 0 &&
                          commitData.map((val, index) => {
                            return (
                              <tr>
                                <div className="border border-dashed border-gray-300 rounded mb-6">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <img
                                        src={val.deal_image?.image}
                                        className="img-sm ms-n1 me-1"
                                        alt="deal-image"
                                      />
                                    </div>
                                    <div className='w-100 mx-2'>

                                      <div className="me-3 d-flex justify-content-between">
                                        <div
                                          className="text-gray-800 text-hover-primary fw-bold"
                                        >
                                          {val.commitment_no}
                                        </div>
                                        <div>
                                          {val.status == 0 && (
                                            <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
                                          )}
                                          {val.status == 1 && (
                                            <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                                          )}
                                          {val.status == 2 && (
                                            <span className='badge badge-light-info fs-8 fw-bold'>Active</span>
                                          )}
                                          {val.status == 3 && (
                                            <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Expired</span>
                                          )}
                                          {val.status == 4 && (
                                            <span className='badge badge-light-warning fs-8 fw-bold warning_active'>In prpgress</span>
                                          )}
                                          {val.status == 5 && (
                                            <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Return Pending</span>
                                          )}
                                          {val.status == 6 && (
                                            <span className='badge badge-light-info fs-8 p-3 fw-bold'>Return Active</span>
                                          )}
                                          {val.status == 7 && (
                                            <span className='badge badge-light-success fs-8 fw-bold success_active'>Return Completed</span>
                                          )}
                                        </div>
                                      </div>

                                      <div className="d-flex flex-stack">
                                        <span className="text-gray-400 fw-bold">
                                          Qty:
                                          <span
                                            className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                            {" "}{val.commited_qty}
                                          </span>
                                        </span>
                                        <span className="text-gray-400 fw-bold">
                                          Fullfill Qty:
                                          <span
                                            className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                            {" "}{val.fullfill_qty}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    )}
                  </table>
                  {/*<tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 deal_name_commit">
                                  {val.deal_name}
                                  {val.deal_name}
                                  {val.deal_name}
                                </span>
                              </div>
                            </td>
                            <td className="text-center min-w-150px">
                              <span className="text-gray-700 fw-bold fs-6">{val.commitment_no}</span>
                              <div>
                                <span className='text-gray-600 fw-semibold fs-7'>
                                  <CurrencyFormat
                                    decimalScale={2}
                                    value={parseFloat(val.price).toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                  />
                                </span>&nbsp;
                                {val.status == 0 && (
                                  <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
                                )}
                                {val.status == 1 && (
                                  <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                                )}
                                {val.status == 2 && (
                                  <span className='badge badge-light-info fs-8 fw-bold'>Active</span>
                                )}
                                {val.status == 3 && (
                                  <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Expired</span>
                                )}
                              </div>
                            </td>
                            <td className="text-center min-w-150px">
                              <div className="card p-2" style={{ backgroundColor: "#F4F6FA" }}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div className="text-gray-500 fw-bold fs-7">Comm. QTY</div>
                                    <div className="text-gray-500 fw-bold fs-7">Fullfiled QTY</div>
                                  </div>
                                  <div>
                                    <div className="text-gray-700 fw-bold fs-6">{val.commited_qty}</div>
                                    <div className="text-gray-700 fw-bold fs-6">{val.fullfill_qty}</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                                </tr>*/}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <div className="card shadow border card-flush h-lg-100">
              <div className="card-header d-flex justify-content-between  px-5 align-item-senter">
                <div className="mt-5 pb-0">
                  <span className='fs-3 fw-semibold'>Latest Orders</span>
                </div>
                {orderData.length !== 0 &&
                  <div className="text-center mt-5">
                    <Link to="/orders/myorders" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                      <span>View more...</span>
                    </Link>
                  </div>
                }
              </div>
              <div className="card-body pt-0 table_container">
                {orderLoading ? (
                  <div className="item">
                    <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                  </div>
                ) : (
                  <>
                    {orderData.length === 0 &&
                      <div className='fs-5 fw-semibold text-gray-600'>
                        <p>No Orders Available.</p>
                      </div>
                    }
                    {orderData.map((val, index) => {
                      return (
                        <>
                          <div className="d-flex flex-stack" key={index}>
                            <img
                              src={val?.commitment?.deal_image?.image}
                              className="me-4 img-sm"
                              alt="deal-image"
                            />
                            <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                              <div className="me-5">
                                <a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">
                                  {val.order_no}
                                </a>
                                <span className="d-block text-start ps-0">
                                  {val.status == 0 && (
                                    <span className='badge badge-light-success fs-8 fw-bold success_active'>Delivered</span>
                                  )}
                                  {val.status == 1 && (
                                    <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                                  )}
                                  {val.status == 2 && (
                                    <span className='badge badge-light-info fs-8 fw-bold'>Active</span>
                                  )}
                                  {val.status == 3 && (
                                    <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Expired</span>
                                  )}
                                  {val.status == 4 && (
                                    <span className='badge badge-light-warning fs-8 fw-bold warning_active'>In prpgress</span>
                                  )}
                                  {val.status == 5 && (
                                    <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Return Pending</span>
                                  )}
                                  {val.status == 6 && (
                                    <span className='badge badge-light-info fs-8 p-3 fw-bold'>Return Active</span>
                                  )}
                                  {val.status == 7 && (
                                    <span className='badge badge-light-success fs-8 fw-bold success_active'>Return Completed</span>
                                  )}
                                </span>
                              </div>
                              <div className="align-items-center">
                                <div>
                                  <span className="text-gray-800 fw-bold fs-6 me-3 d-block">Qty: {val.commited_qty}</span>
                                </div>
                                <div className="m-0">
                                  <span className="badge badge-light-success fs-base">
                                    {/*<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>*/}
                                    <CurrencyFormat
                                      decimalScale={2}
                                      value={parseInt(val.commissiom).toFixed(2)}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-3" />
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <div className="card shadow border card-flush h-lg-100">
              <div className="card-header d-flex justify-content-between  px-5 align-item-senter">
                <div className="mt-5 pb-0">
                  <span className='fs-3 fw-semibold'>Latest Trackings</span>
                </div>
                {trackingData.length !== 0 &&
                  <div className="text-center mt-5">
                    <Link to="/tracking" className='btn btn-sm btn-primary' style={{ backgroundColor: "#EA2467" }}>
                      <span>View more...</span>
                    </Link>
                  </div>
                }
              </div>
              {trackingLoading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <div className="card-body pt-0 table_container">
                  {trackingData.length === 0 &&
                    <div className='fs-5 fw-semibold text-gray-600'>
                      <p>No Trackings Available.</p>
                    </div>
                  }
                  {trackingData.map((val, index) => {
                    return (
                      <>
                        <div className="m-0">
                          <div className="d-flex align-items-sm-center mb-5">
                            <div className="symbol symbol-45px me-4">
                              <span className="symbol-label bg-primary">
                                <i className="ki-duotone ki-truck text-inverse-primary fs-1">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                </i>
                              </span>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-700 fw-bold d-block fs-4">
                                  {val.tracking_no}
                                </span>
                              </div>
                              <span className="fw-bold my-2">
                                {val.status == 0 && (
                                  <span className='badge badge-light-success fs-8 fw-bold success_active'>Delivered</span>
                                )}
                                {val.status == 1 && (
                                  <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                                )}
                                {val.status == 2 && (
                                  <span className='badge badge-light-info fs-8 fw-bold'>Active</span>
                                )}
                                {val.status == 3 && (
                                  <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Expired</span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="timeline">
                            {val.tracking_items?.map((item, ind) => {
                              return (
                                <>
                                  <div className="timeline-item align-items-center mb-7">
                                    <div className="timeline-line w-40px mt-6 mb-n12" />
                                    <div className="timeline-icon" style={{ marginLeft: 11 }}>
                                      <i className="ki-duotone ki-cd fs-2 text-danger">
                                        <span className="path1" />
                                        <span className="path2" />
                                      </i>
                                    </div>
                                    <div className="timeline-content m-0">
                                      <div className='d-flex justify-content-between'>
                                        <span className="fs-6 text-gray-600 fw-semibold d-block">
                                          {item.commitment_no}
                                        </span>
                                        <span className="fs-6 text-gray-800 fw-semibold d-block">
                                          Qty: {item.qty}
                                        </span>
                                      </div>
                                      <span className="fs-8 fw-bold text-gray-800">
                                        {item.deal}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </div>
                        <div className="separator separator-dashed my-6" />
                      </>
                    )
                  })}

                </div>
              )}
            </div>
          </div>

        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/*<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>

      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
      </div>

      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-md-5 mb-xl-10'>
        <ListsWidget26 className='h-lg-50' />
</div>*/}

        <div className='col-xxl-6'>
          {/*<EngageWidget10 className='h-md-100' />*/}
        </div>
      </div>

      <div className='row gy-5 gx-xl-8'>
        {/*<div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>*/}
      </div>

      <div className='row gy-5 g-xl-8'>
        {/*<div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
  </div>*/}
      </div>

      <div className='row g-5 gx-xxl-8'>
        {/*<div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
</div>*/}
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
