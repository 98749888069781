/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../modules/Toast-Message/TosatMessage';

const API_URL = process.env.REACT_APP_API_URL;
const COMMIT_URL = `${API_URL}/deal-commit`;

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddCommits = ({ show, handleClose, propsData, changedColor }) => {

    const navigate = useNavigate();

    const [commitValue, setCommitValue] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const addMyCommits = async (deal_id, commited_qty) => {
        if (commitValue == 0 || commitValue === "") {
            setErrorMsg("enter commit quantity");
        } else {
            setErrorMsg("");
            await axios.post(COMMIT_URL, {
                deal_id: deal_id,
                commited_qty: commited_qty,
                commission: changedColor.commission ? changedColor.commission : propsData.default_color.commission,
                price: changedColor.price ? changedColor.price : propsData.default_color.price,
                attribute_code: changedColor.attribute_code ? changedColor.attribute_code : propsData.default_color.attribute_code,
            })
                .then((response) => {
                    setCommitValue(0);
                    showToastMessageSuccess(response.data.message);
                    setTimeout(() => {
                        navigate("/mycommitments");
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response && error.response.data) {
                        const errorResponse = error.response.data;
                        if (errorResponse.data) {
                            const fieldErrors = errorResponse.message;
                            showToastMessageError(fieldErrors);
                            setErrorMsg(fieldErrors);
                        }
                    } else {
                        showToastMessageError('An error occurred.');
                    };
                });
        };
    };

    const handleCommitChange = (e) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        setCommitValue(newValue);
    };

    // useEffect(() => {
    //     console.log("changedColor", changedColor);
    // }, [changedColor]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header pb-0'>
                <h2>Add Commitment</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>
            <ToastContainer />

            <div className='col-sm-12'>
                <div className='card'>
                    <div className='card-body '>
                        <div className="row">
                            <div className="card-rounded text-center col-sm-3">
                                <img src={changedColor.image ? changedColor.image : propsData.default_color?.image} alt='' style={{ width: "200px", height: "200px", objectFit: "contain" }} />
                            </div>
                            <div className='shadow-xs col-sm-9 card-rounded p-5  z-index-1 bg-body' >
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center flex-wrap w-100'>
                                        <div className='mb-1 pe-3 flex-grow-1'>
                                            <a href='#' className='fs-5 text-gray-700 text-hover-primary'>
                                                <strong>{propsData.name}-{changedColor.color ? changedColor.color : propsData.default_color?.color}</strong>
                                            </a>
                                            <div className='text-start mt-5'>
                                                <span className="badge badge-info fs-5 text-white fw-bold p-2"> ${changedColor.price ? changedColor.price : propsData.default_color?.price}</span>
                                            </div>
                                            <div className='d-flex align-items-center mt-5  '>
                                                <div className='form-group mt-5'>
                                                    <input
                                                        type='text'
                                                        value={commitValue}
                                                        onChange={handleCommitChange}
                                                        className='form-control'
                                                        placeholder='Enter Quantity'
                                                    />
                                                    {errorMsg && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-3px" }}>{errorMsg}</p>}
                                                    {/*<span className='fw-semibold text-muted'>Max. Allowed Limit: 50</span>*/}
                                                </div>
                                                <div className='footer_btn2 me-5'>
                                                    <button type='button' onClick={() => {
                                                        addMyCommits(propsData.deal_code, commitValue);
                                                    }} className=''><i className="fa-solid fa-thumbs-up"></i>&nbsp; Commit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { AddCommits };
