/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddressDetails = (props) => {

    const datas = props.profileData;

    return (
        <div className="shadow card p-5 m-5">
            <div className='card-header cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_addrerss'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Address Details</h3>
                </div>
            </div>

            <div id='kt_account_addrerss'>
                <div className='card-body pb-0'>

                    {/* Street Address */}
                    <div className='row mb-7'>
                        <label className='col-lg-3 fw-bold text-muted'>
                            Street Address
                        </label>
                        <div className='col-lg-8'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Street Address"
                                value={datas.billingDetails ? (datas.billingDetails.address1 ? datas.billingDetails.address1 : "---") : "---"}
                                disabled
                            />
                        </div>
                    </div>

                    {/* Floor or Building */}
                    <div className='row mb-7'>
                        <label className='col-lg-3 fw-bold text-muted'>
                            Floor or Building
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Floor or Building"
                                value={datas.billingDetails ? (datas.billingDetails.address2 ? datas.billingDetails.address2 : "---") : "---"}
                                disabled
                            />
                        </div>
                    </div>

                    {/* City */}
                    <div className='row mb-7'>
                        <label className='col-lg-3 fw-bold text-muted'>
                            City
                        </label>
                        <div className='col-lg-8'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                value={datas.billingDetails ? (datas.billingDetails.city ? datas.billingDetails.city : "---") : "---"}
                                disabled
                            />
                        </div>
                    </div>

                    {/* Zipcode */}
                    <div className='row mb-7'>
                        <label className='col-lg-3 fw-bold text-muted'>
                            Zipcode
                        </label>
                        <div className='col-lg-8'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Zipcode"
                                value={datas.billingDetails ? (datas.billingDetails.zipcode ? datas.billingDetails.zipcode : "---") : "---"}
                                disabled
                            />
                        </div>
                    </div>

                    {/* Country */}
                    <div className='row mb-7'>
                        <label className='col-lg-3 fw-bold text-muted'>
                            Country
                        </label>
                        <div className='col-lg-8'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                value={datas.billingDetails ? (datas.billingDetails.country ? datas.billingDetails.country : "---") : "---"}
                                disabled
                            />
                        </div>
                    </div>

                    {/* State */}
                    <div className='row mb-7'>
                        <label className='col-lg-3 fw-bold text-muted'>
                            State
                        </label>
                        <div className='col-lg-8'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                value={datas.billingDetails ? (datas.billingDetails.state ? datas.billingDetails.state : "---") : "---"}
                                disabled
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export { AddressDetails };
