import { useEffect, useState } from 'react';
import '../../../../Style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';
import axios from 'axios';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
}

const ChangePassword = () => {

    const [loading2, setLoading2] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showPasswordForm, setPasswordForm] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const API_URL = process.env.REACT_APP_API_URL;
    const RESET_PASS = `${API_URL}/password/update`;

    const apiToken = getLocalData().api_token;

    const updatePass = async () => {
        setLoading2(true);
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        if (newPassword === "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword: 'Please enter new password'
            }));
            setLoading2(false);
        } else if (confirmPassword === "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword: '',
                confirmPassword: 'Please Enter confirm password'
            }));
            setLoading2(false);
        } else if (confirmPassword !== newPassword) {
            setLoading2(false);
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: 'Passwords must be same'
            }));
        } else {
            setBtnLoading(true);
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword: '',
                confirmPassword: ''
            }));
            await axios.post(RESET_PASS, {
                password: newPassword,
            })
                .then((response) => {
                    showToastMessageSuccess(response.data.message);
                    setNewPassword("");
                    setConfirmPassword("");
                    setLoading2(false);
                    setBtnLoading(false);
                    setPasswordForm(false);
                })
                .catch((error) => {
                    console.error(error);
                    showToastMessageError(error.message);
                    setLoading2(false);
                    setBtnLoading(false);
                });
        }
    }

    return (
        <div className="shadow card p-5 m-5">
            <ToastContainer />
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_signin_method'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Sign-in Method</h3>
                </div>
            </div>

            <div id='kt_account_signin_method' className='collapse show'>
                <div className='card-body border-top'>

                    <div className='separator separator-dashed'></div>

                    <div className='d-flex flex-wrap align-items-center'>
                        <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                            <div className='fs-6 fw-bolder mb-1'>Password</div>
                            <div className='fw-bold text-gray-600'>************</div>
                        </div>

                        <div
                            id='kt_signin_password_edit'
                            className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                        >
                            <form
                                id='kt_signin_change_password'
                                className='form'
                                noValidate
                            >
                                <div className='row mb-1'>

                                    {/* Password */}
                                    <div className='col-lg-4'>
                                        <div className='fv-row mb-5'>
                                            <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                                                New Password <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type='password'
                                                className='form-control form-control-lg form-control-solid '
                                                id='newpassword'
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                style={{ border: "solid 1px #A1A5B7", marginTop: "-10px" }}
                                                placeholder='Enter New Password'
                                            />
                                            {errors.newPassword !== "" && <p className='error_msg'>{errors.newPassword}</p>}
                                        </div>
                                    </div>

                                    {/* Confirm Password */}
                                    <div className='col-lg-4'>
                                        <div className='fv-row mb-3'>
                                            <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                                                Confirm New Password <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type='password'
                                                className='form-control form-control-lg form-control-solid '
                                                id='confirmpassword'
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                style={{ border: "solid 1px #A1A5B7", marginTop: "-10px" }}
                                                placeholder='Enter Confirm Password'
                                            />
                                            {errors.confirmPassword !== "" && <p className='error_msg'>{errors.confirmPassword}</p>}
                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex'>
                                    <button
                                        id='kt_password_submit'
                                        type='submit'
                                        className='btn btn-primary me-2 px-6'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            updatePass();
                                        }}
                                    >
                                        {loading2 ? (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                                                Please wait...
                                            </span>
                                        ) : 'Update Password'}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setPasswordForm(false);
                                            setNewPassword("");
                                            setConfirmPassword("");
                                            setErrors((prevErrors) => ({
                                                ...prevErrors,
                                                newPassword: '',
                                                confirmPassword: ''
                                            }));
                                        }}
                                        id='kt_password_cancel'
                                        type='button'
                                        className='btn btn-color-gray-400 btn-active-light-primary px-6'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div
                            id='kt_signin_password_button'
                            className={'ms-auto footer_btn2 ' + (showPasswordForm && 'd-none')}
                        >
                            <button
                                onClick={() => {
                                    setPasswordForm(true)
                                }}
                                className='btn-active-light-primary'
                            >
                                <i className="fa-solid fa-pen"></i>&nbsp; Reset Password
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
