import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from "react-js-loader";
import CurrencyFormat from 'react-currency-format';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const OrderDetails = () => {

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);

  const apiToken = getLocalData().api_token;

  const API_URL = process.env.REACT_APP_API_URL;
  const ORDERS = `${API_URL}/user/orders`;

  const getOrderData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(ORDERS, config)
      .then((response) => {
        console.log("OrderData", response.data.data);
        setOrderData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrderData();
  }, []);

  return (
    <div>
      <h1>Order Details</h1>
      <div className='card shadow'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>SKU</th>
                  <th>Order Id</th>
                  <th>QTY</th>
                  <th>Price</th>
                  <th>Commission</th>
                  <th>UPC</th>
                  <th>Tracking Number</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <tbody>
                  {
                    orderData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.sku}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary d-block mb-1 fs-6'>{val.order_no}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.commited_qty}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.price).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.commissiom).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.upc}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.tracking_id}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.date}</span>
                          </td>
                        </tr>
                      );
                    }
                    )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
