import { useEffect, useState } from 'react';
import { CreateSupport } from './CreateSupport';
import axios from 'axios';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const Support = () => {

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_SUPPORT = `${API_URL}/supports`;
  const apiToken = getLocalData().api_token;

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [supportApiData, setSupportApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getApiData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_SUPPORT, config)
      .then((respose) => {
        setSupportApiData(respose.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <div>
      <CreateSupport
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
        getApiData={getApiData}
      />
      <h1>Suports</h1>
      <div className='card shadow p-5'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-130px'>ID</th>
                  <th className='min-w-130px'>Title</th>
                  <th className='min-w-120px'>Type</th>
                  <th className='min-w-120px'>Status</th>
                  <th className='min-w-150px'>User</th>
                  <th className='min-w-120px'>Description</th>
                </tr>
              </thead>
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <tbody>
                  {
                    supportApiData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className='text-dark fw-bold fs-6'>
                            {val.id}
                          </td>
                          <td className='text-dark fw-bold mb-1 fs-6'>
                            {val.title}
                          </td>
                          <td className='text-dark fw-bold mb-1 fs-6'>
                            {val.support_type}
                          </td>
                          <td className='text-dark fw-bold mb-1 fs-6'>
                            {val.status == 1 ? (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Active</span>
                            ) : (
                              <span className='badge badge-light-danger fs-8 fw-bold danger_active'>De-active</span>
                            )}
                          </td>
                          <td className='text-dark fw-bold fs-6'>
                            {val.user_id}
                          </td>
                          <td className='text-dark fw-bold fs-6'>
                            {val.description}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className='foot_btn'>
          <button className='close_btn' disabled={true}>Close Support(s)</button>
          <button className='open_btn' onClick={() => setShowCreateAppModal(true)}>Create Support</button>
        </div>
      </div>
    </div>
  );
}

export default Support;
