/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};

const Referrals = ({ profileData }) => {

    const APP_URL = process.env.REACT_APP_URL;

    const [dynamicClass, setDynamicClass] = useState("form-control form-control-solid me-3 flex-grow-1");
    const [dynamicButton, setDynamicButton] = useState(0);

    const copyToClipFun = () => {
        navigator.clipboard.writeText(`${APP_URL}auth/registration?ref=${profileData?.refral_no}`)
            .then(() => {
                setDynamicButton(2);
                setTimeout(() => {
                    setDynamicClass("form-control form-control-solid custom-shadow border border-1 border-success me-3 flex-grow-1");
                    setDynamicButton(1);
                    setTimeout(() => {
                        setDynamicClass("form-control form-control-solid me-3 flex-grow-1");
                        setDynamicButton(0);
                    }, 2000);
                }, 800);
            })
            .catch(() => {
                alert("Can't copied");
            });
    };

    const [refferaleData, setRefferaleData] = useState([]);
    const [referralPriceData, setReferralPriceData] = useState({});
    const [loading, setLoading] = useState(false);

    const apiToken = getLocalData().api_token;

    const API_URL = process.env.REACT_APP_API_URL;
    const REFFERAL_URL = `${API_URL}/users-refrals`;
    const REFFERAL_DATA_URL = `${API_URL}/user/refral/page`;

    const getRefferalDetails = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(REFFERAL_URL, config)
            .then((response) => {
                setRefferaleData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const getRefferalPriceData = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(REFFERAL_DATA_URL, config)
            .then((response) => {
                console.log("Referral", response.data.data[0]);
                setReferralPriceData(response.data.data[0]);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getRefferalDetails();
        getRefferalPriceData();
    }, []);

    return (
        <div className='cursor-pointer'>
            <div className='m-5 mb-0'>
                <div className="d-flex flex-column flex-column-fluid">
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div className="card shadow mb-5">
                            <div className="card-body py-10">
                                <h2 className="mb-9">Referral Program</h2>
                                <div className="row mb-10">
                                    {/*<div className="col-xl-6 mb-15 mb-xl-0 pe-5">
                                        <h4 className="mb-0">How to use Referral Program</h4>
                                        <p className="fs-6 fw-semibold text-gray-600 py-4 m-0">
                                            Use images to enhance your post, improve its flow, add
                                            humor
                                            <br />
                                            and explain complex topics
                                        </p>
                                        <button
                                            className="btn btn-light btn-active-light-primary fw-bold"
                                        >
                                            Get Started
                                        </button>
                                    </div>*/}
                                    <div className="col-xl-8">
                                        <h4 className="text-gray-800 mb-0">
                                            Your Referral Link
                                        </h4>
                                        {/*<p className="fs-6 fw-semibold text-gray-600 py-4 m-0">
                                            Plan your blog post by choosing a topic, creating an
                                            outline conduct
                                            <br />
                                            research, and checking facts
                                        </p>*/}
                                        <div className="d-flex">
                                            <input
                                                id="kt_referral_link_input"
                                                type="text"
                                                className={dynamicClass}
                                                name="search"
                                                value={`${APP_URL}auth/registration?ref=${profileData?.refral_no}`}
                                            />
                                            {dynamicButton === 0 &&
                                                <button
                                                    id="kt_referral_program_link_copy_btn"
                                                    className="btn btn-light btn-active-light-primary fw-bold flex-shrink-0"
                                                    data-clipboard-target="#kt_referral_link_input"
                                                    onClick={copyToClipFun}
                                                >
                                                    <i className="fa-solid fa-copy"></i> Copy Link
                                                </button>
                                            }
                                            {dynamicButton === 1 &&
                                                <button
                                                    id="kt_referral_program_link_copy_btn"
                                                    className="btn btn-light fw-bold flex-shrink-0 text-success"
                                                    data-clipboard-target="#kt_referral_link_input"
                                                >
                                                    <i className="fa-solid fa-check text-success"></i> Copied!
                                                </button>
                                            }
                                            {dynamicButton === 2 &&
                                                <button
                                                    id="kt_referral_program_link_copy_btn"
                                                    className="btn btn-light fw-bold flex-shrink-0 text-primary"
                                                    data-clipboard-target="#kt_referral_link_input"
                                                    disabled
                                                >
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;&nbsp; Wait!
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                                            <span className="fs-4 fw-semibold text-info pb-1 px-2">
                                                Net Earnings
                                            </span>
                                            <span className="fs-lg-2tx fw-bold d-flex justify-content-center">
                                                {referralPriceData?.credit !== "" ?
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        value={parseFloat(referralPriceData?.credit).toFixed(2)}
                                                        displayType={'text'}
                                                        thousandSeparator={true} prefix={'$'}
                                                    /> :
                                                    "$0.00"
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                                            <span className="fs-4 fw-semibold text-success pb-1 px-2">
                                                Balance
                                            </span>
                                            <span className="fs-lg-2tx fw-bold d-flex justify-content-center">
                                                {referralPriceData?.currentBalance !== "" ?
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        value={parseFloat(referralPriceData?.currentBalance).toFixed(2)}
                                                        displayType={'text'}
                                                        thousandSeparator={true} prefix={'$'}
                                                    /> :
                                                    "$0.00"
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    {/*<div className="col">
                                        <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                                            <span className="fs-4 fw-semibold text-danger pb-1 px-2">
                                                Avg Deal Size
                                            </span>
                                            <span className="fs-lg-2tx fw-bold d-flex justify-content-center">
                                                $
                                                <span
                                                    data-kt-countup="true"
                                                    data-kt-countup-value="2,600"
                                                >
                                                    0
                                                </span>
                                            </span>
                                        </div>
                                    </div>*/}
                                    <div className="col">
                                        <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                                            <span className="fs-4 fw-semibold text-primary pb-1 px-2">
                                                Referral Signups
                                            </span>
                                            <span className="fs-lg-2tx fw-bold d-flex justify-content-center">
                                                {referralPriceData?.referralCount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/*<p className="fs-5 fw-semibold text-gray-600 py-6">
                                    Writing headlines for blog posts is as much an art as it
                                    is a science, and probably warrants its own post, but for
                                    now, all I’d advise is experimenting with what works for
                                    your audience, especially if it’s not resonating with your
                                    audience
                                </p>*/}
                                {/*<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                                    <i className="ki-duotone ki-bank fs-2tx text-primary me-4">
                                        <span className="path1" />
                                        <span className="path2" />
                                    </i>
                                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                        <div className="mb-3 mb-md-0 fw-semibold">
                                            <h4 className="text-gray-900 fw-bold">
                                                Withdraw Your Money to a Bank Account
                                            </h4>
                                            <div className="fs-6 text-gray-700 pe-7">
                                                Withdraw money securily to your bank account.
                                                Commision is $25 per transaction under $50,000
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-primary px-6 align-self-center text-nowrap"
                                        >
                                            Withdraw Money
                                        </button>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                        <div className="card shadow">
                            <div className="card-header card-header-stretch">
                                <div className="card-title">
                                    <h3>Referred Users</h3>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-row-bordered align-middle gy-6">
                                    <thead className="border-bottom border-gray-200 fs-6 fw-bold bg-lighten">
                                        <tr>
                                            <th className="min-w-125px ps-9">User</th>
                                            <th className="min-w-125px px-0">Description</th>
                                            <th className="min-w-125px">Date</th>
                                            <th className="min-w-125px">Bonus</th>
                                            <th className="min-w-125px ps-0">Profit</th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <div className="item">
                                            <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                                        </div>
                                    ) : (
                                        <tbody className="fs-6 fw-semibold text-gray-600">
                                            {refferaleData.length === 0 &&
                                                <tr>
                                                    <td className='text-center' colSpan={5}>You Do Not have any Referrals.</td>
                                                </tr>
                                            }
                                            {refferaleData.length !== 0 &&
                                                refferaleData.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="ps-9"><span dangerouslySetInnerHTML={{ __html: val.user_name }} /></td>
                                                            <td className="ps-9">{val.description}</td>
                                                            <td>{val.date}</td>
                                                            <td>5%</td>
                                                            <td className="text-success">
                                                                <CurrencyFormat decimalScale={2} value={parseFloat(val.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Referrals };
