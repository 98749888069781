/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import { ReqWithdrows } from './ReqWithdrows';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};

const Statements = () => {

    const [showCreateAppModal, setShowCreateAppModal] = useState(false);
    const [statementData, setStatementData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [WithdrawDatas, setWithdrawDatas] = useState([]);
    const [totalBalance, setTotalBalance] = useState({});

    const apiToken = getLocalData().api_token;

    const API_URL = process.env.REACT_APP_API_URL;
    const GET_STATEMENTS_URL = `${API_URL}/user/Statement`;
    const GET_WITHDRAW_URL = `${API_URL}/user/withdraws`;
    const GET_TOTAL_BALANCE = `${API_URL}/user/balance`;

    const getWithdraws = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(GET_WITHDRAW_URL, config)
            .then((response) => {
                setWithdrawDatas(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getStatements = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(GET_STATEMENTS_URL, config)
            .then((response) => {
                setStatementData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const getTotalBalance = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(GET_TOTAL_BALANCE, config)
            .then((response) => {
                setTotalBalance(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const netEarning = () => {
        let netEarn = 0;
        statementData.map((val) => {
            if (val.credit) {
                netEarn = netEarn + val.credit;
            };
            if (val.debit) {
                netEarn = netEarn - val.debit;
            };
        });
        return netEarn;
    };

    useEffect(() => {
        getStatements();
        getWithdraws();
        getTotalBalance();
    }, []);

    return (
        <div className='m-5'>
            <ReqWithdrows
                show={showCreateAppModal}
                handleClose={() => {
                    setShowCreateAppModal(false);
                }}
                getWithdrows={getWithdraws}
                totalBalance={totalBalance}
            />
            <div className="card shadow card-xxl-stretch mb-5">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Earnings</h3>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <span className="fs-5 fw-semibold text-gray-600 pb-5 d-block">
                        Last 30 day earnings calculated. Apart from arranging the order of
                        topics.
                    </span>
                    <div className="d-flex flex-wrap justify-content-between pb-6">
                        <div className="d-flex flex-wrap">
                            <div className="border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6">
                                <span className="fs-2x fw-bold text-gray-800 lh-1">
                                    <span
                                        data-kt-countup="true"
                                        data-kt-countup-value="6,840"
                                        data-kt-countup-prefix="$"
                                    >
                                        {totalBalance.total_credit ?
                                            <CurrencyFormat
                                                decimalScale={2}
                                                value={parseFloat(totalBalance?.total_credit).toFixed(2)}
                                                displayType={'text'}
                                                thousandSeparator={true} prefix={'$'}
                                            /> :
                                            "$0"
                                        }
                                    </span>
                                </span>
                                <span className="fs-6 fw-semibold text-info d-block lh-1 pt-2">
                                    Net Earnings
                                </span>
                            </div>
                            <div className="border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6">
                                <span className="fs-2x fw-bold text-gray-800 lh-1">
                                    {totalBalance.total_credit ?
                                        <CurrencyFormat
                                            decimalScale={2}
                                            value={parseFloat(totalBalance?.total_debit).toFixed(2)}
                                            displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}
                                        /> :
                                        "$0"
                                    }
                                </span>
                                <span className="fs-6 fw-semibold text-danger d-block lh-1 pt-2">
                                    Total Withdrawed
                                </span>
                            </div>
                            <div className="border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6">
                                <span className="fs-2x fw-bold text-gray-800 lh-1">
                                    <span
                                        data-kt-countup="true"
                                        data-kt-countup-value="1,240"
                                        data-kt-countup-prefix="$"
                                    >
                                        {totalBalance.total_credit ?
                                            <CurrencyFormat
                                                decimalScale={2}
                                                value={parseFloat(totalBalance?.total_available_balance).toFixed(2)}
                                                displayType={'text'}
                                                thousandSeparator={true} prefix={'$'}
                                            /> :
                                            "$0"
                                        }
                                    </span>
                                </span>
                                <span className="fs-6 fw-semibold text-success d-block lh-1 pt-2">
                                    Balance
                                </span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <button
                                className="btn btn-primary px-6 flex-shrink-0 align-self-center"
                                onClick={() => {
                                    setShowCreateAppModal(true);
                                }}
                            >
                                Withdraw Money
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card p-8 pb-0 shadow" role="tabpanel">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" role="tabpanel">
                        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_Withdraw_1">Statement</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_Withdraw_2">Withdraw</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="kt_tab_Withdraw_1" role="tabpanel">
                            <div className="card-title">
                                <h3 className="m-0 text-gray-800">Statement</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                    <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                                        <tr className='fw-bold text-muted'>
                                            <th className="min-w-125px ps-9">Statement No</th>
                                            <th className="min-w-150px px-0">Type</th>
                                            <th className="min-w-150px">Decription</th>
                                            <th className="min-w-125px">Amount</th>
                                            <th className="min-w-125px text-center">Date</th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <div className="item">
                                            <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                                        </div>
                                    ) : (
                                        <tbody className="fs-6 fw-semibold text-gray-600">
                                            {statementData.length === 0 &&
                                                <tr>
                                                    <td className='text-center' colSpan={5}>You Do Not have any Statements.</td>
                                                </tr>
                                            }
                                            {statementData.length !== 0 &&
                                                statementData.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="ps-9">{val.statement_no}</td>
                                                            {val.statement_type == "Credit" ?
                                                                <td className="text-success">
                                                                    <span>{val.statement_type}</span>
                                                                </td> :
                                                                <td className="text-danger">
                                                                    <span>{val.statement_type}</span>
                                                                </td>
                                                            }
                                                            <td>{val.description}</td>
                                                            {val.statement_type == "Credit" ?
                                                                <td className="text-success">
                                                                    <CurrencyFormat decimalScale={2} value={parseFloat(val.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                                </td> :
                                                                <td className="text-danger">
                                                                    <CurrencyFormat decimalScale={2} value={parseFloat(val.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                                </td>
                                                            }
                                                            <td>{val.date}</td>

                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="kt_tab_Withdraw_2" role="tabpanel">
                            <div className="card-title">
                                <h3 className="m-0 text-gray-800">Withdraws</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                    <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                                        <tr className='fw-bold text-muted'>
                                            <th className="min-w-150px ps-9">Date</th>
                                            <th className="min-w-125px">Amount</th>
                                            <th className="min-w-125px">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fs-6 fw-semibold text-gray-600">
                                        {WithdrawDatas.length === 0 &&
                                            <tr>
                                                <td className='text-center' colSpan={3}>You Do Not have any Withdrawal.</td>
                                            </tr>
                                        }
                                        {WithdrawDatas.length !== 0 &&
                                            WithdrawDatas.map((val, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="ps-9">{val.date}</td>
                                                        <td className="ps-0">
                                                            <CurrencyFormat
                                                                decimalScale={2}
                                                                value={parseFloat(val.payment).toFixed(2)}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                            />
                                                        </td>
                                                        <td>
                                                            {val.status == 0 && (
                                                                <span className='badge badge-light-success fs-8 fw-bold success_active'>Approved</span>
                                                            )}
                                                            {val.status == 1 && (
                                                                <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                                                            )}
                                                            {val.status == 2 && (
                                                                <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Rejected</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Statements };
