/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../_metronic/helpers'
import axios from 'axios';
import '../Style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../modules/Toast-Message/TosatMessage';

const API_URL = process.env.REACT_APP_API_URL;
const SUPPORT_URL = `${API_URL}/support/create`;

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateSupport = ({ show, handleClose, getApiData }) => {

    const [supportType, setSupportType] = useState("");
    const [supportTitle, setSupportTitle] = useState("");
    const [supportDetails, setSupportDetails] = useState("");
    const [errorMsg, setErrorMsg] = useState({
        type_err: '',
        title_err: '',
        detail_err: '',
    });

    const postSupport = async () => {
        const data = {
            support_type: supportType,
            title: supportTitle,
            description: supportDetails,
        }
        if (supportType === "") {
            setErrorMsg((prevErrors) => ({
                ...prevErrors,
                type_err: 'Plese enter support type'
            }));
        } else if (supportTitle === "") {
            setErrorMsg((prevErrors) => ({
                ...prevErrors,
                type_err: '',
                title_err: 'Plese enter support title'
            }));
        } else if (supportDetails === "") {
            setErrorMsg((prevErrors) => ({
                ...prevErrors,
                title_err: '',
                detail_err: 'Plese enter support details'
            }));
        } else {
            await axios.post(SUPPORT_URL, data)
                .then((response) => {
                    console.log(response);
                    setSupportType("");
                    setSupportTitle("");
                    setSupportDetails("");
                    getApiData();
                    handleClose();
                    showToastMessageSuccess(response.data.message);
                })
                .catch((error) => {
                    console.error(error);
                    showToastMessageError(error.message);
                });
            setErrorMsg({
                type_err: '',
                title_err: '',
                detail_err: '',
            });
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px support_modal'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header'>
                <h2>Create Support</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                    handleClose();
                    setSupportType("");
                    setSupportTitle("");
                    setSupportDetails("");
                }}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card mt-5'>
                <div className="form_card">
                    <form>
                        <div className='supp_type'>
                            <label className='required'>Support Type</label>
                            <select value={supportType} onChange={(e) => setSupportType(e.target.value)} className="form-select" aria-label="Default select example">
                                <option value="">Choose Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                            {errorMsg.type_err !== "" && <p className='error_msg'>{errorMsg.type_err}</p>}
                        </div>
                        <div className="form-group title_field">
                            <label className='required' htmlFor="exampleInputPassword1">Title</label>
                            <input
                                value={supportTitle}
                                onChange={(e) => setSupportTitle(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder='Title'
                            />
                            {errorMsg.title_err !== "" && <p className='error_msg'>{errorMsg.title_err}</p>}
                        </div>
                        <div className="form-group title_field">
                            <label className='required' htmlFor="exampleInputPassword1">Details</label>
                            <textarea
                                value={supportDetails}
                                onChange={(e) => setSupportDetails(e.target.value)}
                                rows={6}
                                type="text"
                                className="form-control support_import"
                                placeholder='Details'
                            />
                            {errorMsg.detail_err !== "" && <p className='error_msg'>{errorMsg.detail_err}</p>}
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal-footer">
                <div className='model-foot-btn footer_btn4'>
                    <button className='canc_btn' onClick={() => handleClose()}>Cancle</button>
                    <button onClick={() => {
                        postSupport();
                    }} className='supp_btn'>Create Support</button>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
}

export { CreateSupport };
