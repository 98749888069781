/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { RegistrationStep } from './RegistrationStep'

export function Registration() {

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <div>
      <RegistrationStep />
      <Link to='/auth/login' className='d-flex justify-content-center'>
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100'
        >
          Cancel
        </button>
      </Link>
    </div>
  );
}
