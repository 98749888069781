import { useState, useEffect } from 'react'
import axios from 'axios';
import Loader from "react-js-loader";

const API_URL = process.env.REACT_APP_API_URL;
const GET_Terms_URL = `${API_URL}/terms`;
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}
const TermsCondition = () => {

  const apiToken = getLocalData().api_token;
  const [activeTab, setActiveTab] = useState(1);
  const [termsApiData, setTermsApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getApiData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_Terms_URL, config)
      .then((response) => {
        setTermsApiData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <div>
      <div className="terms_heading fs-5">
        <p>TERMS & CONDITIONS</p>
      </div>
      <div className="terms_content card shadow p-5">
        {loading ? (
          <div className="item">
            <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
          </div>
        ) : (
          <div className="row">
            <div className="col-9 col-md-3">
              <div className="box1">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 d-flex flex-column">
                  {termsApiData.map((val, index) => {
                    return (
                      <li key={index} className="nav-item">
                        <a className="nav-link" onClick={() => setActiveTab(index + 1)} data-bs-toggle="tab" href={`#kt_tab_pane_${index + 1}`}>{val.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-9">
              <div className="tab-content" id="myTabContent">
                {
                  termsApiData.map((val, index) => {
                    return (
                      <div key={index} className={`tab-pane fade ${activeTab === (index + 1) ? 'show active' : ''}`} id={`kt_tab_pane_${index + 1}`} role="tabpanel">
                        <div className='option_head'>
                          <p>{val.title}</p>
                        </div>
                        <div className="option_para">
                          {val.description && <p>{val.description}</p>}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TermsCondition;
