import { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError, showToastMessageWarning } from '../../modules/Toast-Message/TosatMessage';
import CurrencyFormat from 'react-currency-format';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const MyCommitments = () => {

  const [commitData, setCommitData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [updatedQty, setUpdatedQty] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_COMMIT_URL = `${API_URL}/my-commitments`;
  const UPDATE_COMMIT_URL = `${API_URL}/commit-update`;
  const apiToken = getLocalData().api_token;

  const getCommitments = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_COMMIT_URL, config)
      .then((response) => {
        setCommitData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const updateCommitment = async () => {
    if (editId) {
      if (!updatedQty) {
        setErrorMsg("enter commit quantity");
      } else {
        await axios.put(`${UPDATE_COMMIT_URL}/${editId}`, {
          commited_qty: updatedQty,
        })
          .then((response) => {
            setEditId(null);
            getCommitments();
            showToastMessageSuccess(response.data.message);
            setErrorMsg("");
          })
          .catch((error) => {
            console.error(error);
            console.log(error?.response.data);
            if (error.response && error.response.data) {
              const errorResponse = error.response.data;
              if (errorResponse.data) {
                const fieldErrors = errorResponse.message;
                showToastMessageError(fieldErrors);
              }
            } else {
              showToastMessageError('An error occurred.');
            }
            setErrorMsg("");
          });
      }
    }
  };

  useEffect(() => {
    getCommitments();
  }, []);

  // const truncateString = (str) =>{
  //   const maxLength = 30;
  //   if (str.length > maxLength) {
  //     return str.substring(0, maxLength) + '...';
  //   } else {
  //     return str;
  //   }
  // }

  return (
    <div className=''>
      <ToastContainer />
      <h4>My Commitments</h4>
      <div className='card shadow'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='fs-8'>Commitment Id</th>
                  <th className='fs-8'>Deal Id</th>
                  <th className='fs-8 '>Item Discription</th>
                  <th className='fs-8'>SKU</th>
                  <th className='fs-8'>Status</th>
                  <th className='fs-8'>Count</th>
                  <th className='fs-8 '>Fullfiled</th>
                  <th className='fs-8'>Price</th>
                  <th className='fs-8'>Commission</th>
                  <th className='fs-8'>Total</th>
                  <th className='fs-8'>Created</th>
                </tr>
              </thead>
              {loading ? (
                <tr className="item">
                  <td><Loader type="spinner-cub" bgColor={"#FD8908"} size={50} /></td>
                </tr>
              ) : (
                <tbody>
                  {commitData.length === 0 &&
                    <div className='fs-5 fw-semibold text-gray-600'>
                      <p>No Commitments Available.</p>
                    </div>
                  }
                  {commitData.length !== 0 &&
                    commitData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className='text-dark fs-8  fw-bold'>
                              {val.commitment_no}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark d-block fs-8 fw-bold'>
                              {val.deal_no}
                            </span>
                          </td>
                          <td>
                            <div className='deal_name_commit'>
                              <span className='text-dark d-block fs-8 fw-bold'>
                                {val.deal_name}
                              </span>
                            </div>
                            <span className='text-success fs-9 fw-bold'>Expires in: {val.expires}</span>
                          </td>
                          <td>
                            <span className='text-dark d-block fs-8 fw-bold'>
                              {val.sku}
                            </span>
                          </td>
                          <td className='text-dark fw-bold fs-8'>
                            {val.status == 0 && (
                              <span className='badge badge-light-success fs-8 p-3 fw-bold success_active'>Received</span>
                            )}
                            {val.status == 1 && (
                              <span className='badge badge-warning fs-8 p-3 fw-bold'>Pending</span>
                            )}
                            {val.status == 2 && (
                              <span className='badge badge-info fs-8 p-3 fw-bold'>Active</span>
                            )}
                            {val.status == 3 && (
                              <span className='badge badge-danger fs-8 p-3 fw-bold'>Expired</span>
                            )}
                            {val.status == 4 && (
                              <span className='badge badge-warning fs-8 p-3 fw-bold'>In prpgress</span>
                            )}
                            {val.status == 5 && (
                              <span className='badge badge-warning fs-8 p-3 fw-bold'>Return Pending</span>
                            )}
                            {val.status == 6 && (
                              <span className='badge badge-info fs-8 p-3 fw-bold'>Return Active</span>
                            )}
                            {val.status == 7 && (
                              <span className='badge badge-success fs-8 p-3 fw-bold'>Return Completed</span>
                            )}
                          </td>
                          <td>
                            {editId !== val.commit_code && (
                              <span>
                                {val.commited_qty}
                                {val.status == 1 && <span
                                  className='btn btn-icon btn-bg-light text-hover-primary btn-active-color-primary btn-sm me-1'
                                >
                                  <button
                                    onClick={() => {
                                      setEditId(val.commit_code);
                                      setUpdatedQty(val.commited_qty);
                                    }}
                                    className='btn'
                                  >
                                    <KTIcon iconName='pencil' className='fs-3' />
                                  </button>
                                </span>}
                              </span>
                            )}
                            {editId == val.commit_code && (
                              <div className='w-200px'>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={updatedQty}
                                  onChange={(e) => setUpdatedQty(e.target.value.replace(/[^0-9]/g, ''))}
                                  placeholder='Enter Commit Qty'
                                />
                                {errorMsg && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMsg}</p>}
                                <button onClick={updateCommitment} className='btn btn-success btn-sm ml-10 mt-5' style={{ marginRight: "15px" }}>Update</button>
                                <button className='btn btn-danger btn-sm mt-5' onClick={() => {
                                  setEditId(null);
                                  setUpdatedQty(null);
                                  setErrorMsg("");
                                }}>Cancle</button>
                              </div>
                            )}
                          </td>
                          <td className='text-dark fs-8'>
                            {val.fullfill_qty}
                          </td>
                          <td>
                            <span className='text-dark fs-8'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.price).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fs-8'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.commission).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold fs-8'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.total_commision).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />

                            </span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {val.date}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCommitments;
