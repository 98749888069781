/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import CurrencyFormat from 'react-currency-format';
import './Deal.css';


const modalsRoot = document.getElementById('root-modals') || document.body;

const Details = ({ show2, handleClose2, propsData2, showCreateAppModalCommit, setShowCreateAppModalCommit, setChangedColor }) => {
    const [dealDetail, setDealDeatils] = useState(propsData2?.default_color);
    const [selectedID, setSelectedID] = useState(propsData2?.default_color?.attribute_code);
    const [showCreateAppModal, setShowCreateAppModal] = useState(false);
    const totalPrice = (parseFloat(dealDetail.price) + parseFloat(dealDetail.commission)).toFixed(2);

    const itemPrice = (val) => {
        var data = {};
        if (propsData2?.commission_status == 1) {
            const commission = parseFloat(val.commission) - parseFloat(val.price);
            data = {
                orderPrice: parseFloat(val.price) - commission,
                actualPrice: parseFloat(val.price),
                commission: commission,
                total: parseFloat(val.price) + commission,
            }
        } else if (propsData2?.retail == 1) {
            data = {
                orderPrice: parseFloat(val.price),
                actualPrice: parseFloat(val.price),
                commission: 0,
                total: parseFloat(val.price),
            }
        } else if (propsData2?.below_cost == 1) {
            data = {
                orderPrice: parseFloat(val.price),
                actualPrice: parseFloat(val.price),
                commission: 0,
                total: parseFloat(val.price),
            }
        }
        return data;
    };

    useEffect(() => {
        if (showCreateAppModalCommit) {
            handleClose2();
        }
    }, [showCreateAppModalCommit]);

    useEffect(() => {
        setChangedColor(dealDetail);
    }, [dealDetail]);

    //     <AddCommits
    //     show={showCreateAppModalCommit}
    //     propsData={propsData2}
    //     imgPath={toAbsoluteUrl('/media/images/1.png')}
    //     handleClose={() => {
    //         setShowCreateAppModalCommit(false);
    //     }}
    //     changedColor={dealDetail.attribute_code}
    //     changedColor2={dealDetail.color}
    //     changePrice={dealDetail.price}
    // />

    return createPortal(

        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show2}
            onHide={handleClose2}
            backdrop={true}
        >
            <div className='btn btn-sm btn-icon btn-active-color-primary' style={{ marginLeft: "auto" }} onClick={handleClose2}>
                <KTIcon className='fs-1' iconName='cross' />
            </div>

            <div className=''>
                <div className='card mt-5'>
                    <div className="ribbon ribbon-start ribbon-clip" style={{ top: '10px' }}>
                        <div className="ribbon-label">
                            In Stock
                            <span className="ribbon-inner bg-info"></span>
                        </div>

                    </div>
                    <div className='row col-12'>
                        <div className='card-body col-4'>
                            <div className={`card-rounded text-center`} >
                                <img src={dealDetail.image ? dealDetail.image : propsData2?.default_color?.image} alt='' style={{ width: "200px", height: "auto" }} />
                            </div>
                        </div>
                        <div className='col-8'>
                            <div className='mb-5'>
                                <div className=''>
                                    <div
                                        className='card-rounded p-2 z-index-1 bg-body'
                                    >
                                        <div className='shadow-xs align-items-center' style={{ marginLeft: "-15px", marginTop: "-15px", padding: "10px" }}>
                                            <div className='d-flex align-items-center flex-wrap w-100'>
                                                <div className='pe-3 flex-grow-1'>
                                                    <a href='#' className='fs-5 fw-bold text-gray-700 text-hover-primary'>
                                                        <strong>{propsData2?.name}-{dealDetail.color}</strong>
                                                    </a>
                                                    <div className='d-flex align-items-center mt-5 justify-content-between'>
                                                        <div className='me-5'>
                                                            <div className='fs-7 symbol-label'>
                                                                Deal Expires:
                                                            </div>
                                                            <div className='fs-7 symbol-label'>
                                                                Price:
                                                            </div>
                                                            {itemPrice(dealDetail).commission !== 0 && (
                                                                <div className='fs-7 symbol-label'>
                                                                    Commission:
                                                                </div>
                                                            )}
                                                            <div className='fs-7 symbol-label'>
                                                                Model:
                                                            </div>
                                                            <div className='fs-3 fw-bold mt-4 symbol-label'>
                                                                Total:
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <div className='fs-7 text-gray-800 pe-1'>
                                                                {propsData2?.expires}
                                                            </div>
                                                            <div className='fs-7 text-gray-800 pe-1'>
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    value={itemPrice(dealDetail).actualPrice.toFixed(2)}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={'$'}
                                                                />
                                                            </div>
                                                            {itemPrice(dealDetail).commission !== 0 && (
                                                                <div className='fs-7 text-gray-800 pe-1'>
                                                                    <CurrencyFormat
                                                                        decimalScale={2}
                                                                        value={itemPrice(dealDetail).commission.toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        prefix={'$'}
                                                                    />
                                                                </div>
                                                            )}
                                                            <div className='fs-7 text-gray-800 pe-1'>
                                                                {dealDetail.model}
                                                            </div>
                                                            <div className='fw-bold mt-4 fs-3 text-gray-800 pe-1'>
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    value={itemPrice(dealDetail).total.toFixed(2)}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={'$'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ul className='deal_colors'>
                                                            <div className='mt-3 fw-bold fs-7 mb-2'>
                                                                Color Available:
                                                            </div>
                                                            {propsData2?.colors.map((item, index) => {
                                                                return (
                                                                    <li key={index} onClick={() => {
                                                                        setDealDeatils(item);
                                                                        setSelectedID(item.attribute_code);
                                                                    }} ><div className={item.attribute_code == selectedID ? 'color-item deal_colors_selected' : 'color-item deal_colors_select'} style={{ borderColor: `${item.color_code}` }}><div className='colorBox' style={{ background: `${item.color_code}` }}></div></div></li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                <div className='d-flex col-12'>
                                                    <div className='col-12'>
                                                        <div className='mt-3 fw-bold fs-7 '>
                                                            Shipping Location:
                                                        </div>
                                                        <div>
                                                             {propsData2?.shipping_address} -
                                                            <span>{propsData2?.shipping_zipcode}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: "end" }}>
                                                <button
                                                    type='button'
                                                    className='bg-light mt-5'
                                                    style={{
                                                        borderRadius: "20px",
                                                        border: "solid 1px #3C612E",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        color: "#3C612E"
                                                    }}>
                                                    On Sale Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none">
                                <div className='fw-bold fs-4 text-primary'>
                                    Items
                                </div>
                                <div style={{ marginTop: "20px", padding: "10px" }} className='shadow-xs row col-12'>
                                    <div className='col-2'>
                                        <div className={`border-right card-rounded text-center`}>
                                            <img src={propsData2?.image} alt='' style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                                        </div>
                                    </div>
                                    <div className='border-left col-8'>
                                        <div className='fw-bold fs-7'>
                                            {propsData2?.name}
                                        </div>
                                        <div className='row col-12 mt-5'>
                                            <div className='col-6'>
                                                <div><strong>UPC &nbsp;</strong> {propsData2?.upc}</div>
                                                <div><strong>Model &nbsp;</strong> {propsData2?.model}</div>
                                                <div><strong>Price &nbsp;</strong> ${propsData2?.price}</div>
                                            </div>
                                            <div className='col-6'>
                                                <div><strong>User Limit &nbsp;</strong> {propsData2?.user_limit}</div>
                                                <div><strong>Commission &nbsp;</strong> ${propsData2?.commission}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><hr />
            <div className="d-flex justify-content-end">
                <div className='' style={{ paddingRight: "10px", paddingBottom: "10px" }}>
                    <button
                        onClick={handleClose2}
                        type='button'
                        className='btn btn-danger  ml-5'>Cancel</button>
                </div>
                <div className=' ' style={{ paddingRight: "10px", paddingBottom: "10px" }}>
                    <button
                        onClick={() => {
                            setShowCreateAppModalCommit(true);
                        }}
                        type='button'
                        className='btn btn-warning fw-bold'><i className="fa-solid fa-thumbs-up"></i>&nbsp; Commit</button>
                </div>
            </div>
        </Modal>,
        modalsRoot

    );
}

export { Details };
