import { useState, useEffect } from 'react';
import '../Style.css';
import axios from 'axios';
import Loader from "react-js-loader";

const API_URL = process.env.REACT_APP_API_URL;
const FAQS_URL = `${API_URL}/faqs`;
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const FAQs = () => {

  const apiToken = getLocalData().api_token;
  const [apiData, setApiData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredValue, setFilteredValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const getApiData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(FAQS_URL, config)
      .then((response) => {
        setApiData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <div className='card shadow p-5'>
      <div className="faq_heading">
        <p>Frequently Asked Questions</p>
      </div>
      <div className="search_input">
        <input
          type='text'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
          placeholder='Search'
        />
      </div>
      <div className="faq_content">
        {loading ? (
          <div className="item">
            <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="accordion" id="accordionExample">
                {
                  apiData
                    .filter((item) => {
                      if (item === "") {
                        return item;
                      } else {
                        return item.title.toLowerCase().includes(searchInput);
                      }
                    })
                    .map((val, index) => {
                      return (
                        <div key={val.id} className="card mt-3">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                {val.title}
                              </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                {val.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                }
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="accordion" id="accordionExample1">
                {
                  apiData
                    .filter((item) => {
                      if (item === "") {
                        return item;
                      } else {
                        return item.title.toLowerCase().includes(searchInput);
                      }
                    })
                    .map((val, index) => {
                      return (
                        <div key={val.id} className="card mt-3">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${index + 100}`}>
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index + 100}`} aria-expanded="false" aria-controls={`collapse${index + 100}`}>
                                {val.title}
                              </button>
                            </h2>
                            <div id={`collapse${index + 100}`} className="accordion-collapse collapse" aria-labelledby={`heading${index + 100}`} data-bs-parent="#accordionExample1">
                              <div className="accordion-body">
                                {val.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FAQs;
