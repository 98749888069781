import { useEffect, useState, useRef } from 'react';
import { AddCommits } from './AddCommits';
import { Details } from './Details';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import Loader from "react-js-loader";
import '../Style.css';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const Deals = () => {

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [showCreateAppModal2, setShowCreateAppModal2] = useState(false);

  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [propsData, setPropsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changedColor, setChangedColor] = useState({});

  const [sliderValues, setSliderValues] = useState({ from: 0, to: 100000 });

  const API_URL = process.env.REACT_APP_API_URL;
  const DEALS_URL = `${API_URL}/deals`;

  const getApiData = async () => {
    setLoading(true);
    try {
      await axios.get(DEALS_URL)
        .then((res) => {
          setApiData(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSliderChange = (e) => {
    setSliderValues(() => ({
      from: e[0],
      to: e[1],
    }));
  };

  const onSaleData = () => {
    setFilteredData(apiData.filter((items) => {
      return items.on_sale_now == 1;
    }));
  };

  const belowCostData = () => {
    setFilteredData(apiData.filter((items) => {
      return items.below_cost == 1;
    }));
  };

  const inStoreData = () => {
    setFilteredData(apiData.filter((items) => {
      return items.in_store == 1;
    }));
  };

  const allActiveData = () => {
    setFilteredData(apiData);
  };

  const itemPrice = (val) => {
    var data = {};
    if (val.commission_status == 1) {
      const commission = parseFloat(val.default_color?.commission) - parseFloat(val.default_color?.price);
      data = {
        status:1,
        orderPrice: parseFloat(val.default_color?.commission) - commission,
        actualPrice: parseFloat(val.default_color?.price),
        commission: commission,
      }
    } else if (val.retail == 1) {
      data = {
        status:0,
        orderPrice: parseFloat(val.default_color?.price),
        actualPrice: parseFloat(val.default_color?.price),
        commission: 0,
      }
    } else if (val.below_cost == 1) {
      data = {
        status:1,
        orderPrice: parseFloat(val.default_color?.price),
        actualPrice: parseFloat(val.default_color?.commission),
        commission: 0,
      }
    }

    return data;
  };

  useEffect(() => {
    const filterOnSale = apiData.filter((items) => {
      return items.on_sale_now == 1;
    });
    setFilteredData(filterOnSale);
  }, [apiData]);

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        {showCreateAppModal &&
          <AddCommits
            show={showCreateAppModal}
            propsData={propsData}
            handleClose={() => {
              setShowCreateAppModal(false);
            }}
            changedColor={changedColor}
          />
        }
        {showCreateAppModal2 &&
          <Details
            show2={showCreateAppModal2}
            propsData2={propsData}
            handleClose2={() => {
              setShowCreateAppModal2(false);
            }}
            setShowCreateAppModalCommit={setShowCreateAppModal}
            showCreateAppModalCommit={showCreateAppModal}
            setChangedColor={setChangedColor}
          />
        }
      </div>
      <div>
        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
          <li className="nav-item">
            <a
              className="nav-link active"
              style={{ cursor: "pointer" }}
              data-bs-toggle="tab"
              href="#kt_tab_pane_1"
              onClick={() => {
                setLoading(true);
                onSaleData();
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              On Sale Now
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              style={{ cursor: "pointer" }}
              data-bs-toggle="tab"
              href="#kt_tab_pane_2"
              onClick={() => {
                setLoading(true);
                belowCostData();
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              Below Cost
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              style={{ cursor: "pointer" }}
              data-bs-toggle="tab"
              href="#kt_tab_pane_3"
              onClick={() => {
                setLoading(true);
                inStoreData();
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              In Store
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              style={{ cursor: "pointer" }}
              data-bs-toggle="tab"
              href="#kt_tab_pane_4"
              onClick={() => {
                setLoading(true);
                allActiveData();
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              All Actives
            </a>
          </li>
        </ul>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <form>
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
                type='text'
                className="form-control"
                placeholder='Search...'
              />
            </form>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 range_slider" style={{ position: "relative" }}>
            <div className="thumb-values" style={{ left: `${(sliderValues.from / 100000) * 100}%` }}>
              <span className='fw-bold'>${sliderValues.from}</span>
            </div>
            <div className="thumb-values" style={{ left: `${((sliderValues.to / 100000) * 100) - 6}%` }}>
              <span className='fw-bold'>${sliderValues.to}</span>
            </div>
            <RangeSlider
              min={0}
              max={100000}
              step={10}
              value={[sliderValues.from, sliderValues.to]}
              onInput={(e) => { handleSliderChange(e); }}
            />
            <div className="thumb-values2">
              <span className='fw-bold'>0%</span>
              <span className='fw-bold'>100%</span>
            </div>
          </div>
        </div>

        {/* On Sale Now Data */}
        {loading ? (
          <div className="item" style={{ marginTop: "10%" }}>
            <Loader type="spinner-cub" bgColor={"#FD8908"} size={80} />
          </div>
        ) : (
          <div className='row'>
            {filteredData.length === 0 &&
              <div className='text-center mt-10'>
                <h3 className='text-gray-600'>No Deals Found...</h3>
              </div>
            }
            {filteredData
              .filter((item) => {
                if (item === '') {
                  return item;
                } else {
                  return (item.name.toLowerCase().includes(searchValue));
                }
              })
              .filter((item) => {
                return (item.total >= (sliderValues.from) && item.total <= (sliderValues.to));
              })
              .map((val, index) => {
                return (
                  <div key={index} className='col-sm-6 col-lg-4 col-xl-3 col-md-4'>
                    <div className='card shadow mt-5'>
                      <div className="ribbon ribbon-end ribbon-clip" style={{ top: '10px' }}>
                        <div className="ribbon-label">
                          In Stock
                          <span className="ribbon-inner bg-info"></span>
                        </div>

                      </div>
                      <div className='card-body p-5'>
                        <div className="card-rounded text-center">
                          <img src={val.default_color && val.default_color?.image} alt='val.name' style={{ width: "100%", height: "200px", maxHeight: "450px", objectFit: "contain" }} />
                        </div>
                        <div className='shadow-xs card-rounded z-index-1 bg-body'>
                          <div className='d-flex align-items-center' style={{ minHeight: '230px' }}>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                              <div className='px-3 flex-grow-1'>
                                <a className='fs-5 text-gray-700 fw-semibold text-hover-primary deal_description'>
                                  {val.name}-{val?.default_color && val.default_color?.color}
                                </a>
                                <div className='d-flex justify-content-around'>
                                  <span className="fs-5 text-success fw-bold p-2">
                                    <CurrencyFormat
                                      decimalScale={2}
                                      value={itemPrice(val).orderPrice}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                    />
                                  </span>
                                  {itemPrice(val).status === 1 && (
                                    <span className="fs-5 text-muted p-2 text-decoration-line-through">
                                      <CurrencyFormat
                                        decimalScale={2}
                                        value={itemPrice(val).actualPrice}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                      />
                                    </span>
                                  )}
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <div className='me-5'>
                                    {itemPrice(val).commission !== 0 && (
                                      <div className='symbol-label'>
                                        Commission:
                                      </div>
                                    )}
                                    <div className='symbol-label'>
                                      Deal Expires:
                                    </div>
                                  </div>
                                  <div>
                                    {itemPrice(val).commission !== 0 && (
                                      <div className=' fs-5 text-gray-800'>
                                        <CurrencyFormat
                                          decimalScale={2}
                                          value={itemPrice(val).commission.toFixed(2)}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                        />
                                      </div>
                                    )}
                                    <div className=' fs-5 text-gray-800'>{val.expires}</div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column mt-2'>
                                  <div className='text-dark fw-bold'>Strores</div>
                                  <div className='d-flex'>
                                    {val.default_color?.stores.map((item, index) => {
                                      return (
                                        <a
                                          key={index}
                                          href={item?.store_url}
                                          target='_blank'
                                          className='symbol symbol-35px me-2'
                                          data-bs-toggle='tooltip'
                                          title='Ana Stone'
                                        >
                                          <img src={item?.store?.image} alt='store' />
                                        </a>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-around'>
                          {/*<button onClick={() => {
                            setShowCreateAppModal(true);
                            setPropsData(val);
                          }} type='button' className='btn btn-info' >Commit </button>*/}
                          <button onClick={() => {
                            setShowCreateAppModal2(true);
                            setPropsData(val);
                          }} type='button' className='btn btn-warning btn-outline mt-4 ml-5' style={{ background: '#FFB6C1' }}>View Deal </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}

export default Deals;
