import React, { useState } from 'react';
import CreateDispute from './CreateDispute';

const CreateNew = () => {

    const [showCreateAppModal, setShowCreateAppModal] = useState(false);

    return (
        <div className="">
            <CreateDispute
                show={showCreateAppModal}
                handleClose={() => {
                    setShowCreateAppModal(false);
                }}
            />
            <div className='mb-5 footer_btn3'>
                <button onClick={() => setShowCreateAppModal(true)} className='btn btn-primary'><i className="fa-solid fa-circle-plus fs-3"></i>&nbsp; Create Dispute</button>
            </div>
            <div className='card shadow'>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                                style={{ border: "solid 1px #A1A5B7" }}
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-60px'>SKU</th>
                                    <th className='min-w-60px'>Order ID</th>
                                    <th className='min-w-60px'>User</th>
                                    <th className='min-w-60px'>Price</th>
                                    <th className='min-w-60px'>Commission</th>
                                    <th className='min-w-60px'>UPC</th>
                                    <th className='min-w-60px'>QTY</th>
                                    <th className='min-w-60px'>Tracking Number</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/*<div className="col-2">
                <h2 style={{ color:"#0273B5" }}><i className="fa-solid fa-circle-plus fs-3" style={{ color:"#0273B5" }}></i> Create Dispute</h2>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <i className="fa-solid fa-circle-question"></i> &nbsp;&nbsp;<span>Help</span> <span>Guidelines</span>
                        </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                        </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    );
}

export default CreateNew;
