/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';

const modalsRoot = document.getElementById('root-modals') || document.body;

const ReqWithdrows = ({ show, handleClose, getWithdrows, totalBalance }) => {

    const [amount, setAmount] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const API_URL = process.env.REACT_APP_API_URL;
    const WITHDROW_REQ_URL = `${API_URL}/user/withdraw-request`;

    const reqWithdrow = async () => {
        if (!amount) {
            setErrorMsg("enter amount");
        } else if (amount < 1) {
            setErrorMsg("enter valid amount");
        } else if (totalBalance.total_available_balance < 250) {
            setErrorMsg("You do not have sufficient balance");
        } else {
            setErrorMsg("");
            await axios.post(WITHDROW_REQ_URL, {
                payment: amount
            })
                .then((response) => {
                    showToastMessageSuccess(response.data.message);
                    setTimeout(() => {
                        handleClose();
                    }, 1000);
                    setAmount("");
                    getWithdrows();
                })
                .catch((error) => {
                    console.error(error);
                    showToastMessageError(error.response.data.message);
                });
        };
    };

    useEffect(() => {
        setAmount("");
        setErrorMsg("");
    }, [handleClose]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header pb-0'>
                <h2>Request Withdraws</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card'>
                <div className='card-body d-flex pt-0'>
                    <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                            <div className='d-flex align-items-center mt-5 justify-content-between'>
                                <div style={{ marginTop: "-5px" }}>
                                    <input
                                        type='number'
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        className='form-control'
                                        placeholder='Enter Amount ($)'
                                    />
                                    {errorMsg && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMsg}</p>}
                                    <div className='fw-semibold text-muted' style={{ marginTop: "15px", marginBottom: "-18px" }}>minimum balance should be $250</div>
                                </div>
                                <div className='footer_btn2'>
                                    <button
                                        type='button'
                                        onClick={reqWithdrow}
                                    >
                                        <i className="fa-solid fa-thumbs-up"></i>&nbsp; Withdraw
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { ReqWithdrows };
