import * as Yup from 'yup'

export interface ICreateAccount {
  accountType: string
  firstName: string
  lastName: string
  email: string
  cellphone: number
  dob: Date
  company: string
  password: string
  confirmPassword: string
  address: string
  address2: string
  city: string
  zipcode: number
  country: string
  state: string
}

const createAccountSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().required().label('Email'),
    cellphone: Yup.number().required().label('Cell Phone'),
    dob: Yup.date().required().label('DOB'),
    company: Yup.string().required().label('Company'),
    password: Yup.string().required().label('Password'),
    confirmPassword: Yup.string().required().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match").label('Confirm Password'),
  }),
  Yup.object({
    address: Yup.string().required().label('Address'),
    address2: Yup.string().label('Address 2'),
    city: Yup.string().required().label('City'),
    zipcode: Yup.number().required().label('Zipcode'),
    country: Yup.string().required().label('Country'),
    state: Yup.string().required().label('State'),
  })
]

const inits: ICreateAccount = {
  accountType: 'personal',
  firstName: '',
  lastName: '',
  email: '',
  cellphone: 0,
  dob: new Date(26, 5, 2023),
  company: '',
  password: '',
  confirmPassword: '',
  address: '',
  address2: '',
  city: '',
  zipcode: 0,
  country: '',
  state: '',
}

export {createAccountSchemas, inits}
