import { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { ImportTracking } from './ImportTracking';
import axios from 'axios';
import Loader from "react-js-loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError, showToastMessageWarning } from '../../modules/Toast-Message/TosatMessage';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const TrackingManagement = () => {

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [checkBox, setCheckBox] = useState([]);
  const [editId, setEditId] = useState(null);
  const [updatedOtp, setUpdatedOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const apiToken = getLocalData().api_token;
  const API_URL = process.env.REACT_APP_API_URL;
  const GET_TRACK_COMMIT = `${API_URL}/trackCommit`;
  const DELETE_TRACK_COMMIT = `${API_URL}/user/trackingOrder/delete`;
  const UPDATE_OTP_URL = `${API_URL}/user/update-OTP`;

  const getTrackingCommit = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_TRACK_COMMIT, config)
      .then((response) => {
        setTrackingData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleCheckChange = (e, index, val) => {
    const { checked } = e.target;
    setCheckBox((prevCheckBox) => {
      const updatedCheckBox = [...prevCheckBox];
      updatedCheckBox[index] = {
        ...updatedCheckBox[index],
        check: checked,
        value: val,
      };
      return updatedCheckBox;
    });
  };

  const deleteTrack = async () => {
    const getCheck = checkBox.filter((vall) => {
      return vall.check === true;
    });
    const deletedIds = getCheck.map((val) => {
      return val.value.tracking_code;
    });
    if (deletedIds.length === 0) {
      showToastMessageWarning("Please select a Track to delete");
    } else {
      setDeleteLoading(true);
      await axios.post(DELETE_TRACK_COMMIT, {
        id: deletedIds
      })
        .then((response) => {
          showToastMessageSuccess(response.data.message);
          getTrackingCommit();
          setDeleteLoading(false);
        })
        .catch((error) => {
          console.error(error);
          showToastMessageError(error.message);
          setDeleteLoading(false);
        });
    }
  };

  const updateOTP = async () => {
    const data = {
      tracking_code: editId,
      otp: updatedOtp,
    };
    if (editId) {
      if (!updatedOtp) {
        setErrorMsg("enter otp*");
      } else if (updatedOtp.toString().length < 4) {
        setErrorMsg("minimum 4 digits*");
      } else if (updatedOtp.toString().length > 6) {
        setErrorMsg("maximum 6 digits*");
      } else {
        setBtnLoading(true);
        setErrorMsg("");
        await axios.post(UPDATE_OTP_URL, {
          otp: data
        })
          .then((response) => {
            showToastMessageSuccess(response.data.message);
            getTrackingCommit();
            setBtnLoading(false);
          })
          .catch((error) => {
            console.error(error);
            showToastMessageError(error.message);
            setBtnLoading(false);
          });
        setEditId(null);
      };
    };
  };

  useEffect(() => {
    const initialCheckBoxState = trackingData.map((val) => ({ check: false, value: val }));
    setCheckBox(initialCheckBoxState);
  }, [trackingData]);

  useEffect(() => {
    getTrackingCommit();
  }, []);

  return (
    <div>
      <ImportTracking
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
        getTrackingCommit={getTrackingCommit}
      />
      <ToastContainer />
      <h1>Tracking Management</h1>
      <div className='card shadow p-5'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Recent Orders</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 orders</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'>
                    {/*<div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={selectAllCheck}
                        onChange={(e) => setSelectAllCheck(e.target.checked)}
                      />
                    </div>*/}
                  </th>
                  <th className='min-w-120px'>ID</th>
                  <th className='min-w-150px'>Carrier</th>
                  <th className='min-w-150px'>Order No</th>
                  <th className='min-w-120px'>Tracking No</th>
                  <th className='min-w-120px'>OTP</th>
                  <th className='min-w-120px'>Quantity</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px'>Created Date</th>
                </tr>
              </thead>
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
              ) : (
                <tbody>
                  {trackingData.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value={checkBox[index] ? checkBox[index].check : false}
                              onChange={(e) => { handleCheckChange(e, index, val) }}
                            />
                          </div>
                        </td>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            <img src={val.store_logo} alt='' style={{ width: "80px", height: "50px" }} />
                          </span>
                        </td>
                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                          {val.order_no}
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {val.tracking_id}
                          </span>
                        </td>
                        <td>
                          {editId !== val.tracking_code && (
                            <span className='text-dark fw-bold d-block mb-1 fs-6'>
                              {val.otp ? val.otp : "---"}&nbsp;&nbsp;&nbsp;
                              {val.status == 1 &&
                                <span
                                  className='btn btn-icon btn-bg-light text-hover-primary btn-active-color-primary btn-sm me-1'
                                >
                                  <button
                                    onClick={() => {
                                      setEditId(val.tracking_code);
                                      setUpdatedOtp(val.otp);
                                    }}
                                    className='btn'
                                  >
                                    <KTIcon iconName='pencil' className='fs-3' />
                                  </button>
                                </span>
                              }
                            </span>
                          )}

                          {editId == val.tracking_code && (
                            <div className='w-200px'>
                              <input
                                type='text'
                                className='form-control'
                                value={updatedOtp}
                                onChange={(e) => setUpdatedOtp(e.target.value.replace(/[^0-9]/g, ''))}
                                placeholder='Enter OTP'
                              />

                              {errorMsg && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMsg}</p>}

                              {btnLoading ?
                                <button className='btn btn-success btn-sm mt-5'>
                                  Wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </button> :
                                <button
                                  onClick={updateOTP}
                                  className='btn btn-success btn-sm ml-10 mt-5'
                                  style={{ marginRight: "15px" }}
                                >
                                  Update
                                </button>
                              }

                              {!btnLoading &&
                                <button
                                  className='btn btn-danger btn-sm mt-5'
                                  onClick={() => {
                                    setEditId(null);
                                    setUpdatedOtp(null);
                                    setErrorMsg("");
                                  }}
                                >
                                  Cancle
                                </button>
                              }
                            </div>
                          )}
                        </td>
                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                          {val.qty}
                        </td>
                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                          {val.status == 0 && (
                            <span className='badge badge-success fs-6 fw-bold'>Received</span>
                          )}
                          {val.status == 1 && (
                            <span className='badge badge-warning fs-6 fw-bold'>Pending</span>
                          )}
                          {val.status == 2 && (
                            <span className='badge badge-info fs-6 fw-bold'>Active</span>
                          )}
                          {val.status == 3 && (
                            <span className='badge badge-danger fs-6 fw-bold'>Expired</span>
                          )}
                        </td>
                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                          {val.created_date}
                          {/*<span className='text-muted fw-semibold text-muted d-block fs-7'>{getTimeFormat(val.created_at)}</span>*/}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className='footer_btn3'>
          {!deleteLoading ?
            <button
              onClick={deleteTrack}
            >
              <i className="fa-solid fa-trash"></i>&nbsp; Delete Tracking
            </button> :
            <button>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span> Deleting...
            </button>
          }&nbsp;&nbsp;

          <button
            onClick={() => setShowCreateAppModal(true)}
          >
            <i className="fa-solid fa-location-dot"></i>&nbsp; Tracking Import
          </button>
        </div>
      </div>
    </div>
  );
}

export default TrackingManagement;
