import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { showToastMessageError, showToastMessageSuccess } from '../../Toast-Message/TosatMessage'
import { ToastContainer } from 'react-toastify'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<string>("")
  const [showInputField, setShowInputField] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passError, setPassError] = useState("");
  const [confPassError, setConfPassError] = useState("");

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setOtpError("");
      setPassError("");
      setConfPassError("");
      if (showInputField && otp === "") {
        setOtpError("Enter OTP");
      } else if (showInputField && password === "") {
        setPassError("Enter Password");
      } else if (showInputField && confPassword === "") {
        setConfPassError("Enter Password");
      } else if (showInputField && password !== confPassword) {
        setConfPassError("Password must be same");
      } else {
        setLoading(true)
        setHasErrors("")
        // console.log("Email", values.email);
        setTimeout(() => {
          requestPassword(values.email, otp, password, confPassword)
            .then((res) => {
              setLoading(false)
              showToastMessageSuccess(res?.data?.message);
              if (res.status === 250) {
                setShowInputField(true);
              } else if (res.status === 205) {
                navigate("/auth");
              }
            })
            .catch((err) => {
              const statusCode = err?.response?.status;
              showToastMessageError(err?.response?.data.message);
              setLoading(false);
              setSubmitting(false);
              setStatus('The login detail is incorrect');
              if (statusCode === 404) {
                setHasErrors("Wrong email address, please try again");
                setShowInputField(false);
                setOtpError("");
                setPassError("");
                setConfPassError("");
              }
            })
        }, 1000);
      }
    },
  })

  return (
    <>
      <ToastContainer />
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        {!showInputField &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder='Enter email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container position-absolute'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        }
        {/* end::Form group */}

        {showInputField &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
            <input
              type='text'
              placeholder='Enter otp'
              className='form-control bg-transparent'
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {otpError && <p className='text-danger position-absolute'>{otpError}</p>}
            {/* {otpError && <p className='text-danger position-absolute'>{otpError}</p>} */}
          </div>
        }

        {showInputField &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
            <input
              type='password'
              placeholder='Enter new password'
              className='form-control bg-transparent'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passError && <p className='text-danger position-absolute'>{passError}</p>}
          </div>
        }

        {showInputField &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm New Password</label>
            <input
              type='password'
              placeholder='Enter confirm password'
              className='form-control bg-transparent'
              value={confPassword}
              onChange={(e) => setConfPassword(e.target.value)}
            />
            {confPassError && <p className='text-danger position-absolute'>{confPassError}</p>}
          </div>
        }

        {/* begin::Title */}
        {hasErrors && (
          <div className='mb-lg-15 alert alert-danger position-absolute'>
            <div className='alert-text font-weight-bold'>
              {hasErrors}
            </div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
