import { useState } from 'react';
import '../Style.css';
import { HelpPopup } from './HelpPopup';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const Help = () => {

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <div className='card shadow p-5'>
      <HelpPopup
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
      />
      <div className="help_container">
        <div className="logo">
          <img src={toAbsoluteUrl('/media/logos/logo.png')} />
        </div>
        <div className="contact_detail1">
          <p className='para1'>Thank you for your interest in MAKEMONEYORMILES. Our team is dedicated to helping you with any issues or concerns you may have</p>
          <p className='para2'>Our Mailbox</p>
          <p className='para1'><a href='mailto:support@makemoneyormiles.com'><u>support@makemoneyormiles.com</u></a></p>
          <p className='para3'>Our Phone</p>
          <p className='para1'><a href='tel:+15039195272'><u>+1 503-919-5272</u></a></p>
        </div>
        <div className="contact_detail2">
          <p className='para'>Our Address</p>
          <p className='para'><i><u>7288 NW Baneberry Pl, Portland OR 97229</u></i></p>
        </div>
        <div className="report_btn">
          <button onClick={() => setShowCreateAppModal(true)}><i className="fa-solid fa-bug"></i>&nbsp; Report A Bug</button>
        </div>
        {/* src={toAbsoluteUrl('/media/avatars/300-23.jpg')} */}
      </div>
    </div>
  );
}

export default Help;
