import React from 'react';

const Views = () => {
  return (
    <div className='card shadow'>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                      style={{ border: "solid 1px #A1A5B7" }}
                    />
                  </div>
                </th>
                <th className='min-w-60px'>Verified</th>
                <th className='min-w-60px'>Void</th>
                <th className='min-w-60px'>Commit</th>
                <th className='min-w-60px'>Attach</th>
                <th className='min-w-60px'>Dispute ID</th>
                <th className='min-w-60px'>Status</th>
                <th className='min-w-60px'>Order ID</th>
                <th className='min-w-60px'>Type</th>
                <th className='min-w-150px'>Item</th>
                <th className='min-w-60px'>QTY</th>
                <th className='min-w-60px'>Req. Price</th>
                <th className='min-w-60px'>Current Price</th>
                <th className='min-w-60px'>Req. Comm.</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Views;
