import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';
import axios from 'axios';

const Notifications = ({ profileData, getProfileDetails }) => {

    const datas = profileData;

    const [loading, setLoading] = useState(false)
    const [commission, setCommission] = useState(false);
    const [retailDeal, setRetailDeal] = useState(false);
    const [belowCost, setBelowCost] = useState(false);
    const [onSaleNow, setOnSaleNow] = useState(false);
    const [notOnSale, setNotOnSale] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectAllToggle, setSelectAllToggle] = useState(true);

    const API_URL = process.env.REACT_APP_API_URL;
    const UPDATE_NOTIFICATION = `${API_URL}/deal-notification/update`;

    const saveChanges = async () => {
        setLoading(true);
        const data = {
            commission: (commission ? 1 : 0),
            retail: (retailDeal ? 1 : 0),
            below_cost: (belowCost ? 1 : 0),
            on_sale_now: (onSaleNow ? 1 : 0),
            not_on_sale_now: (notOnSale ? 1 : 0),
        }
        await axios.post(UPDATE_NOTIFICATION, data)
            .then((response) => {
                console.log(response);
                setLoading(false);
                getProfileDetails();
                showToastMessageSuccess(response.data.message);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                showToastMessageError(error.message);
            });
    };

    useEffect(() => {
        setCommission((datas.commission == 1) ? true : false);
        setRetailDeal((datas.retail == 1) ? true : false);
        setBelowCost((datas.below_cost == 1) ? true : false);
        setOnSaleNow((datas.on_sale_now == 1) ? true : false);
        setNotOnSale((datas.not_on_sale_now == 1) ? true : false);
    }, [datas]);

    const selectAllFun = () => {
        if (selectAllToggle) {
            setCommission(true);
            setRetailDeal(true);
            setBelowCost(true);
            setOnSaleNow(true);
            setNotOnSale(true);
        } else {
            setCommission(false);
            setRetailDeal(false);
            setBelowCost(false);
            setOnSaleNow(false);
            setNotOnSale(false);
        }
        setSelectAllToggle(!selectAllToggle);
    };

    useEffect(() => {
        if (commission && retailDeal && belowCost && onSaleNow && notOnSale) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [commission, retailDeal, belowCost, onSaleNow, notOnSale]);

    return (
        <div className="shadow card p-5 m-5">
            <ToastContainer />
            <div
                className='card-header cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_notifications'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Deal Notifications</h3>
                </div>
            </div>

            <div id='kt_account_notifications' className='collapse show'>
                <form className='form'>
                    <div className='card-body py-0 pb-5'>
                        <div className='fw-bold fs-6'>

                            {/* Select All */}
                            <div className='row mt-5'>
                                <div className="col-9 col-sm-6 col-md-3 col-lg-3">
                                    <div className='min-w-350px border-bottom-0'>Select ALL</div>
                                </div>
                                <div className='col-3 border-bottom-0' onClick={() => selectAllFun()}>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='newsletter1'
                                        checked={selectAll}
                                        onChange={(e) => setSelectAll(e.target.checked)}
                                        style={{ border: "solid 1px #A1A5B7" }}
                                    />
                                </div>
                            </div>

                            {/* Commission Deals */}
                            <div className='row mt-5'>
                                <div className="col-9 col-sm-6 col-md-3 col-lg-3">
                                    <div className='min-w-350px'>Commissions Deals</div>
                                </div>
                                <div className='col-3 border-bottom-0'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='billing1'
                                        checked={commission}
                                        onChange={(e) => setCommission(e.target.checked)}
                                        style={{ border: "solid 1px #A1A5B7" }}
                                    />
                                    <label className='form-check-label ps-2' htmlFor='billing1'></label>
                                </div>
                            </div>

                            {/* Retail Deals */}
                            <div className='row mt-5'>
                                <div className="col-9 col-sm-6 col-md-3 col-lg-3">
                                    <div className='min-w-350px'>Retails Deals</div>
                                </div>
                                <div className='col-3 border-bottom-0'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='team1'
                                        checked={retailDeal}
                                        onChange={(e) => setRetailDeal(e.target.checked)}
                                        style={{ border: "solid 1px #A1A5B7" }}
                                    />
                                    <label className='form-check-label ps-2' htmlFor='team1'></label>
                                </div>
                            </div>

                            {/* Below Cost Deals */}
                            <div className='row mt-5'>
                                <div className="col-9 col-sm-6 col-md-3 col-lg-3">
                                    <div className='min-w-350px'>Below Cost Deals</div>
                                </div>
                                <div className='col-3 border-bottom-0'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='project1'
                                        checked={belowCost}
                                        onChange={(e) => setBelowCost(e.target.checked)}
                                        style={{ border: "solid 1px #A1A5B7" }}
                                    />
                                    <label className='form-check-label ps-2' htmlFor='project1'></label>
                                </div>
                            </div>

                            {/* On Sale Now Deals */}
                            <div className='row mt-5'>
                                <div className="col-9 col-sm-6 col-md-3 col-lg-3">
                                    <div className='min-w-350px'>On Sale Now</div>
                                </div>
                                <div className='col-3 border-bottom-0'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='project1'
                                        checked={onSaleNow}
                                        onChange={(e) => setOnSaleNow(e.target.checked)}
                                        style={{ border: "solid 1px #A1A5B7" }}
                                    />
                                    <label className='form-check-label ps-2' htmlFor='project1'></label>
                                </div>
                            </div>

                            {/* Not On Sale Now Deals */}
                            <div className='row mt-5'>
                                <div className="col-9 col-sm-6 col-md-3 col-lg-3">
                                    <div className='min-w-350px'>Not on Sale Now</div>
                                </div>
                                <div className='col-3 border-bottom-0'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='project1'
                                        checked={notOnSale}
                                        onChange={(e) => setNotOnSale(e.target.checked)}
                                        style={{ border: "solid 1px #A1A5B7" }}
                                    />
                                    <label className='form-check-label ps-2' htmlFor='project1'></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer footer_btn2 d-flex justify-content-end py-6 px-9'>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={saveChanges}
                        >
                            {loading ? (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            ) : (
                                <span>
                                    <i className="fa-solid fa-floppy-disk"></i>&nbsp; Save Changes
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Notifications;
