/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../modules/Toast-Message/TosatMessage';

const API_URL = process.env.REACT_APP_API_URL;
const COMMIT_URL = `${API_URL}/deal-commit`;

const modalsRoot = document.getElementById('root-modals') || document.body;

const UploadReturnImg = ({ show, handleClose, getReturnsData, refund_code }) => {

    const [imgValue, setImgValue] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");

    const API_URL = process.env.REACT_APP_API_URL;
    const ADD_RETURNS = `${API_URL}/refund-request-create`;

    const addReturns = async () => {
        setErrorMsg("");
        const data = {
            refund_img: imgValue,
            refund_code: refund_code
        }
        const formData = new FormData();
        formData.append('refund_img', imgValue);
        formData.append('refund_code', refund_code);
        if (!imgValue) {
            setErrorMsg("please select image");
        } else {
            console.log("refund_code", formData);
            await axios.post(ADD_RETURNS, formData)
                .then((response) => {
                    console.log(response.data);
                    getReturnsData();
                    handleClose();
                })
                .catch((error) => {
                    console.error(error);
                });
        };
    };

    // useEffect(() => { console.log("refund_code", refund_code); }, [refund_code]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header pb-0'>
                <h2>Add Returns</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>
            <ToastContainer />

            <div className='col-sm-12'>
                <div className='card'>
                    <div className='card-body '>
                        <div className="row">
                            <label className='form-label required'>Upload image</label>
                            <div className='col-8'>
                                <input
                                    type='file'
                                    className='form-control'
                                    onChange={(e) => setImgValue(e.target.files[0])}
                                />
                                {errorMsg && <p className='text-danger fw-semibold position-absolute'>{errorMsg}</p>}
                            </div>
                            <div className="col-4">
                                <div className="d-flex justify-content-center">
                                    <button onClick={addReturns} className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { UploadReturnImg };
