/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../modules/Toast-Message/TosatMessage';
import axios from 'axios';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};

const Banking = ({ profileData2, getProfileDetails2, loading2 }) => {

    const [editMode, setEditMode] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountType, setAccountType] = useState(0);
    const [accountNumber, setAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [profileData, setProfileData] = useState({});
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        accountNameErr: '',
        bankNameErr: '',
        accountTypeErr: '',
        accountNumberErr: '',
        routingNumberErr: '',
    });

    const API_URL = process.env.REACT_APP_API_URL;
    const UPDATE_BANKING = `${API_URL}/banking-Detail/update`;

    const apiToken = getLocalData().api_token;
    const DETAILS = `${API_URL}/details`;

    const getProfileDetails = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(DETAILS, config)
            .then((response) => {
                setProfileData(response.data.data?.bankingDetails);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const updateData = async () => {
        const data = {
            account_name: accountName,
            bank_name: bankName,
            bank_account_type: accountType,
            account_number: accountNumber,
            routing_number: routingNumber
        }
        setErrorMessage((prev) => ({
            ...prev,
            accountNameErr: '',
            bankNameErr: '',
            accountTypeErr: '',
            accountNumberErr: '',
            routingNumberErr: ''
        }));
        if (!accountName || accountName === "") {
            setErrorMessage((prev) => ({
                ...prev,
                accountNameErr: 'enter account name'
            }));
        } else if (!bankName || bankName === "") {
            setErrorMessage((prev) => ({
                ...prev,
                bankNameErr: 'enter bank name'
            }));
        } else if (!accountType || accountType == 0) {
            setErrorMessage((prev) => ({
                ...prev,
                accountTypeErr: 'select account type'
            }));
        } else if (!accountNumber || accountNumber === "") {
            setErrorMessage((prev) => ({
                ...prev,
                accountNumberErr: 'enter account number'
            }));
        } else if (!routingNumber || routingNumber === "") {
            setErrorMessage((prev) => ({
                ...prev,
                routingNumberErr: 'enter routing number'
            }));
        } else if (routingNumber && routingNumber.length !== 9) {
            setErrorMessage((prev) => ({
                ...prev,
                routingNumberErr: 'enter routing number with 9 digits'
            }));
        } else {
            setBtnLoading(true);
            await axios.post(UPDATE_BANKING, data)
                .then((response) => {
                    setEditMode(false);
                    showToastMessageSuccess(response.data.message);
                    getProfileDetails();
                    setBtnLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    showToastMessageError(error?.response?.data?.message);
                    setBtnLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!editMode) {
            setErrorMessage((prev) => ({
                ...prev,
                accountNameErr: '',
                bankNameErr: '',
                accountTypeErr: '',
                accountNumberErr: '',
                routingNumberErr: ''
            }));
            setAccountName(profileData ? profileData.account_name : "");
            setBankName(profileData ? profileData.bank_name : "");
            setAccountType(profileData ? profileData.account_type : 0);
            setAccountNumber(profileData ? profileData.account_number : "");
            setRoutingNumber(profileData ? profileData.routing_number : "");
        }
    }, [editMode]);

    useEffect(() => {
        getProfileDetails();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        if (profileData) {
            setAccountName(profileData.account_name);
            setBankName(profileData.bank_name);
            setAccountType(profileData.account_type);
            setAccountNumber(profileData.account_number);
            setRoutingNumber(profileData.routing_number);
        }
    }, [profileData]);

    return (
        <div className="shadow card p-5 m-5">
            <ToastContainer />
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Banking Information</h3>
                </div>
            </div>
            {loading ? (
                <div className="item">
                    <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
                </div>
            ) : (
                <div className='card-body pb-0'>

                    {/* Account Name */}
                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Account Name</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.account_name : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Account Name"
                                    value={accountName}
                                    onChange={(e) => setAccountName(e.target.value)}
                                    disabled={!editMode}
                                />
                                {errorMessage.accountNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.accountNameErr}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Bank Name */}
                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Bank Name</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.bank_name : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bank Name"
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                    disabled={!editMode}
                                />
                                {errorMessage.bankNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.bankNameErr}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Account Type */}
                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Account Type</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? (profileData.account_type == 1 ? "Personal Checking" : "Person Saving") : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <select
                                    className="form-select"
                                    value={accountType}
                                    onChange={(e) => setAccountType(e.target.value)}
                                    disabled
                                >
                                    <option value={0}>--Select--</option>
                                    <option value={1}>Personal Checking</option>
                                    <option value={2}>Business checking</option>
                                </select>
                                {errorMessage.accountTypeErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.accountTypeErr}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Account Number */}
                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Account Number</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.account_number : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Account Number"
                                    value={accountNumber}
                                    onChange={(e) => setAccountNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                    disabled={!editMode}
                                />
                                {errorMessage.accountNumberErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.accountNumberErr}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Routing Number */}
                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Routing Number</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.routing_number : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Routing Number"
                                    value={routingNumber}
                                    onChange={(e) => setRoutingNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                    disabled={!editMode}
                                />
                                {errorMessage.routingNumberErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.routingNumberErr}</p>}
                            </div>
                        </div>
                    </div>

                </div>
            )}
            <div className='card-footer footer_btn2 d-flex justify-content-start py-6 px-9'>
                {!editMode && (
                    <button
                        type='button'
                        onClick={() => setEditMode(true)}
                    >
                        <i className="fa-solid fa-pen"></i> Edit
                    </button>
                )}
                {editMode && !btnLoading && (
                    <button
                        type='button'
                        onClick={updateData}
                    >
                        <i className="fa-solid fa-pen"></i> Update
                    </button>
                )}&nbsp;&nbsp;
                {editMode && btnLoading && (
                    <button
                        type='button'
                    >
                        Wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </button>
                )}&nbsp;&nbsp;
                {editMode && !btnLoading && (
                    <button
                        type='button'
                        onClick={() => {
                            setEditMode(false);
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i> Cancle
                    </button>
                )}
            </div>
        </div>
    );
}

export { Banking };
