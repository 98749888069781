/* eslint-disable jsx-a11y/anchor-is-valid */
 
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import Loader from "react-js-loader";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};
const AccountHeader = (props) => {
  const apiToken = getLocalData().api_token;

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_STATEMENTS_URL = `${API_URL}/user/Statement`;
  const GET_WITHDRAW_URL = `${API_URL}/user/withdraws`;
  const GET_TOTAL_BALANCE = `${API_URL}/user/balance`;

  const datas = props.profileData;
  const loader = props.loading;
  const [totalBalance, setTotalBalance] = useState({});

  const getTotalBalance = async () => {
    const config = {
        headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_TOTAL_BALANCE, config)
        .then((response) => {
            setTotalBalance(response.data.data);
        })
        .catch((error) => {
            console.error(error);
        });
};
 
useEffect(() => { 
  getTotalBalance();
}, []);

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>
          {loader ? (
            <div className="item">
              <Loader type="spinner-cub" bgColor={"#FD8908"} size={50} />
            </div>
          ) : (
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {datas && datas?.first_name} {datas && datas?.last_name}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {datas?.billingDetails && (
                        <span>{datas?.billingDetails?.address1}{datas?.billingDetails?.address1 && ","} {datas?.billingDetails?.city} {datas?.billingDetails?.city && "-"} {datas?.billingDetails?.country}</span>
                      )}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {datas && datas?.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>{totalBalance?.total_credit ?
                          <CurrencyFormat
                              decimalScale={2}
                              value={parseFloat(totalBalance?.total_credit).toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true} prefix={'$'}
                          /> :
                          "$0"
                      }</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { AccountHeader };
