/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';

const modalsRoot = document.getElementById('root-modals') || document.body;

const HelpPopup = ({ show, handleClose }) => {

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogclassName='modal-dialog modal-dialog-centered mw-900px support_modal2'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2>Report a Bug</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                    handleClose();
                }}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card'>
                <div className="bug_report_form">
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group title_field">
                                <label for="exampleInputPassword1">Bug Summary*</label>
                                <input type="text" className="form-control" placeholder='Enter Bug Summary' />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group title_field">
                                <label for="exampleInputPassword1"></label>
                                <input type="file" className="form-control" placeholder='Attachment' />
                            </div>
                        </div>
                    </div>
                    <div className="form-group title_field">
                        <label for="exampleInputPassword1">Bug Decription*</label>
                        <textarea type="text" rows={4} className="form-control" placeholder='Enter Bug Decription' />
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className='footer_btn4 px-3'>
                    <button onClick={handleClose}>Cancle</button>
                </div>
                <div className='footer_btn2'>
                    <button className=''><i className="fa-solid fa-envelope"></i> Send Bug Via Email</button>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
}

export { HelpPopup };
