import React from 'react';
import Labels from './Labels';
import RequestLabels from './RequestLabels';

const ShippingLabels = () => {
  return (
    <div>
      <h2>Request Shipping Labels</h2>
      <div className="tab-pane fade show active" role="tabpanel">
        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
          <li className="nav-item">
            <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">Labels</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Request Labels</a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
            <Labels />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <RequestLabels />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShippingLabels;
