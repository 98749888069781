/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../Toast-Message/TosatMessage';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const API_URL = process.env.REACT_APP_API_URL;
const COMMIT_URL = `${API_URL}/deal-commit`;

const modalsRoot = document.getElementById('root-modals') || document.body;

const HelpPopup = ({ show, handleClose }) => {

    const navigate = useNavigate();

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='d-flex justify-content-center pb-0 mt-5'>
                <h2>MakeMoneyorMiles</h2>
            </div>
            <div className='modal-header d-flex justify-content-center pb-0 fs-6 text-muted' style={{ marginTop: "-28px" }}>
                <p>WhatsApp group</p>
            </div>
            <ToastContainer />

            <div className='d-flex justify-content-center'>
                <img src={toAbsoluteUrl('/media/images/wa-barcode.png')} alt='barcode' style={{ height: "250px", width: "300px", objectFit: "cover" }} />
            </div>
            <div className='d-flex justify-content-center fs-6'>
                <div style={{ width: "350px", marginLeft: "25px" }}>
                    <p>Scan or upload this QR code using the WhatsApp</p>
                </div>
            </div>
            <div className='d-flex justify-content-center fs-6'>
                <p style={{ marginTop:"-15px" }}>camera to join this group</p>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { HelpPopup };
