/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../_metronic/helpers';

const modalsRoot = document.getElementById('root-modals') || document.body;

const CreateDispute = ({ show, handleClose }) => {

    const [radioValue, setRadioValue] = useState(0);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px support_modal2'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2 style={{ color: "#0273B5" }}>Create Dispute</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                    handleClose();
                }}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <i className="fa-solid fa-circle-question"></i> &nbsp;&nbsp;<span>Help</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Guidelines</span>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <ul>
                                <li>
                                    <p>We are currently only accepting price dispute.</p>
                                </li>
                                <li>
                                    <p>We do not allow multiple price and/or commission per item in a single receipt. So if you are partially disputing on item in a receipt, the price will have to split up.</p>
                                </li>
                                <li>
                                    <p>Select a new price and/or a commission before requesting a dispute.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="m-5">
                <div className="d-flex justify-content-around">
                    <h5>Item: ---</h5>
                    <h5>Qty: ---</h5>
                    <h5>Price: ---</h5>
                    <h5>Commission: ---</h5>
                </div>
            </div>

            <div className="row m-5">
                <div className='col-6'>
                    <label className="form-label">Price</label>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='$'
                    />
                </div>
                <div className='col-6'>
                    <label className="form-label">Commission</label>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='$'
                    />
                </div>
            </div>

            <div className='m-8 mt-0 mb-0'>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-label">
                        I have a commitment at this price too
                    </label>
                </div>
            </div>

            <div className='m-8' onChange={(e) => setRadioValue(e.target.value)}>
                <div className="form-check">
                    <input className="form-check-input" value={1} type="radio" name="createDispute" />
                    <label className="form-label" >
                        Commitment Price
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" value={2} type="radio" name="createDispute" />
                    <label className="form-label" >
                        Proof Of Order
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" value={3} type="radio" name="createDispute" />
                    <label className="form-label" >
                        Price Hike
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" value={4} type="radio" name="createDispute" />
                    <label className="form-label" >
                        Locked On Fedex Scann
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" value={5} type="radio" name="createDispute" />
                    <label className="form-label" >
                        No Commitment
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" value={6} type="radio" name="createDispute" />
                    <label className="form-label" >
                        Other
                    </label>
                </div>
            </div>

            <div className="row m-5">
                <div className="col-8">
                    <textarea
                        className='form-control support_import'
                        type='text'
                        placeholder='What did we get wrong?'
                        rows={1}
                    />
                </div>
                <div className="col-4">
                    <input
                        className='form-control'
                        type='file'
                    />
                </div>
            </div>

            <div className="modal-footer">
                <div className='model-foot-btn'>
                    <button className='supp_btn'><i className="fa-solid fa-arrow-right"></i> Request Dispute</button>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
}

export default CreateDispute;
